import { Discount } from '@Types/cart/Discount';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { Money } from '@Types/product/Money';
import { removeNonAlphanumericAndSpaces } from 'helpers/skuHelpers';
import { StringHelpers } from 'helpers/stringHelpers';
import Cookies from 'js-cookie';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaPurchase = (order: Order, account: any) => {
  const dataLayer = window?.dataLayer;
  const cjeCookie = Cookies?.get('cje');
  const accumalateDiscountCodes = (discountCodes: Discount[]) => {
    let discountCode = '';
    if (discountCodes?.length > 0) {
      discountCodes?.map((discountCodeItem: Discount) => {
        discountCode = discountCode + ' ' + discountCodeItem?.code;
        return discountCode;
      });
    }
    return discountCode;
  };
  const formatPriceToDecimal = (price: Money) => {
    const numericStringValue = price ? parseFloat((price?.centAmount / 100).toString()) : 0.0;
    return parseFloat(numericStringValue.toFixed(2));
  };
  const calculateProductPrice = (lineItem: LineItem) => {
    if (account?.isB2BApproved) {
      return formatPriceToDecimal(lineItem?.discountedPrice ? lineItem?.discountedPrice : lineItem?.price);
    } else {
      return formatPriceToDecimal(lineItem?.price);
    }
  };
  const discountCoupon = accumalateDiscountCodes(order?.discountCodes);
  const discount = formatPriceToDecimal(order?.discountTotal);

  try {
    const purchaseObj = {
      currency: order?.subTotal?.currencyCode,
      transaction_id: order?.orderNumber,
      value: order?.subTotal?.centAmount / 10 ** order?.subTotal?.fractionDigits,
      shipping: order?.shippingAmount?.centAmount / 10 ** order?.shippingAmount?.fractionDigits,
      tax: order?.totalTaxedPrice?.centAmount / 10 ** order?.totalTaxedPrice?.fractionDigits,
      coupon: order?.discountCodes?.[0]?.code || null,
      items: [
        order.lineItems.map((lineItem) => {
          return {
            item_id: lineItem.variant.sku,
            item_name: lineItem.name,
            index: lineItem.variant.sku,
            item_variant: lineItem.variant?.attributes?.collection,
            price:
              lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
              lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
            quantity: lineItem.count,
          };
        }),
      ],
    };
    const cjEventObj = {
      subTotal: formatPriceToDecimal(order?.subTotal),
      coupon: discountCoupon,
      discount: account?.isB2BApproved ? 0 : discount,
      orderId: order?.orderNumber,
      FIRECJ: cjeCookie || '',
      products: order.lineItems.map((lineItem) => {
        return {
          productTitle: lineItem?.name,
          productType: lineItem?.productType,
          productSku: removeNonAlphanumericAndSpaces(lineItem?.variant?.sku),
          productPrice: calculateProductPrice(lineItem),
          productQuantity: lineItem?.count,
        };
      }),
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({ cjEvent: null });
    if (order?.email) {
      dataLayer.push({
        event: 'ga4_purchase',
        ecommerce: purchaseObj,
        cjEvent: cjEventObj,
        email: StringHelpers?.convertToSHA256Hash(order?.email),
      });
    } else {
      dataLayer.push({
        event: 'ga4_purchase',
        ecommerce: purchaseObj,
        cjEvent: cjEventObj,
      });
    }
  } catch (error) {
    console.log('ga4 purchase event fail');
  }
};
