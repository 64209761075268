import { StringHelpers } from 'helpers/stringHelpers';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaBeginCheckout = (cart: any) => {
  const dataLayer = window?.dataLayer;
  try {
    const beginCheckoutObj = {
      currency: cart.cartTotal.currencyCode,
      value: cart.cartTotal.centAmount / 10 ** cart.cartTotal.fractionDigits,
      items: [
        cart.lineItems.map((lineItem) => {
          return {
            item_id: lineItem.variant.sku,
            item_name: lineItem.name,
            index: lineItem.variant.sku,
            item_variant: lineItem.variant?.attributes?.collection,
            price:
              lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
              lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
            quantity: lineItem.count,
          };
        }),
      ],
    };
    dataLayer.push({ ecommerce: null });
    if (cart?.email) {
      dataLayer.push({
        event: 'begin_checkout',
        ecommerce: beginCheckoutObj,
        email: StringHelpers?.convertToSHA256Hash(cart?.email),
      });
    } else {
      dataLayer.push({
        event: 'begin_checkout',
        ecommerce: beginCheckoutObj,
      });
    }
  } catch (error) {
    console.log('ga4 begin checkout event fail');
  }
};
