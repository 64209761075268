import { StringHelpers } from 'helpers/stringHelpers';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaUserLogin = (email: string) => {
  const dataLayer = window?.dataLayer;
  try {
    email &&
      dataLayer.push({
        event: 'user_Login',
        email: StringHelpers?.convertToSHA256Hash(email),
      });
  } catch (error) {
    console.log('User login info event fail');
  }
};
