import { removeNonAlphanumericAndSpaces } from 'helpers/skuHelpers';
import { StringHelpers } from 'helpers/stringHelpers';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaViewCart = (cart: any) => {
  const dataLayer = window?.dataLayer;
  try {
    const viewCartObj = {
      currency: cart.cartTotal.currencyCode,
      value: cart.cartTotal.centAmount / 10 ** cart.cartTotal.fractionDigits,
      items: [
        cart.lineItems.map((lineItem) => {
          return {
            item_id: lineItem.variant.sku,
            item_name: lineItem.name,
            index: lineItem.variant.sku,
            item_variant: lineItem.variant?.attributes?.collection,
            price:
              lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
              lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
            quantity: lineItem.count,
          };
        }),
      ],
    };
    const cjEventObj = {
      products: cart.lineItems.map((lineItem) => {
        return {
          productTitle: lineItem?.name,
          productType: lineItem?.productType,
          productSku: removeNonAlphanumericAndSpaces(lineItem?.variant?.sku),
          productPrice: lineItem?.price?.centAmount / 10 ** lineItem?.price?.fractionDigits,
          productQuantity: lineItem?.count,
          productDiscount:
            lineItem?.appliedDiscountPrice?.centAmount / 10 ** lineItem?.appliedDiscountPrice?.fractionDigits,
        };
      }),
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({ cjEvent: null });
    if (cart?.email) {
      dataLayer.push({
        event: 'view_cart',
        ecommerce: viewCartObj,
        cjEvent: cjEventObj,
        email: StringHelpers?.convertToSHA256Hash(cart?.email),
      });
    } else {
      dataLayer.push({
        event: 'view_cart',
        ecommerce: viewCartObj,
        cjEvent: cjEventObj,
      });
    }
  } catch (error) {
    console.log('ga4 view cart event fail');
  }
};
