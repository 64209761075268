import React, { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Account } from '@Types/account/Account';
import { Address as AddressType } from '@Types/account/Address';
import { FormikProps } from 'formik';
import { SERVER_ERROR } from 'helpers/constants/messages';
import { listrakSCAEvent } from 'helpers/listrakHelper';
import { useNotification } from 'helpers/notification/notificationContext';
import { StringHelpers } from 'helpers/stringHelpers';
import { gaAddShippingInfo } from 'helpers/utils/ga4-events/addShippingInfo';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { mode, theme } from 'tailwind.config';
import { useCart } from 'frontastic';
import CloseIcon from '../../../../icons/sol/close';
export interface SuggestAddressProps {
  open: boolean;
  onClose: () => void;
  setloading: (isLoading: boolean) => void;
  validateShipping?: any;
  validateBilling?: any;
  selectedShipping?: AddressType | undefined;
  selectedBilling?: AddressType | undefined;
  setSelectedAddress?: React.Dispatch<React.SetStateAction<AddressType | undefined>>;
  setBillingAddress?: React.Dispatch<React.SetStateAction<AddressType | undefined>>;
  formik?: FormikProps<any>;
  account?: Account;
  isSameAddress?: boolean;
  isShippingValid?: boolean;
  isBillingValid?: boolean;
}
export interface ValidAddressData {
  shippingAddress?: AddressType[];
  billingAddress?: AddressType[];
}

function SuggestAddress({
  open,
  onClose,
  setloading,
  validateShipping,
  validateBilling,
  selectedShipping,
  selectedBilling,
  setSelectedAddress,
  setBillingAddress,
  formik,
  account,
  isSameAddress,
  isShippingValid,
  isBillingValid,
}: SuggestAddressProps) {
  const router = useRouter();
  const { data: cart, updateCart } = useCart();
  const { showNotification } = useNotification();

  const validAddressData: ValidAddressData = {
    shippingAddress: [
      {
        addressId: selectedShipping?.addressId || formik?.values?.addressId || '',
        firstName: selectedShipping?.firstName || formik?.values?.shippingAddress?.firstName || '',
        lastName: selectedShipping?.lastName || formik?.values?.shippingAddress?.lastName || '',
        company: selectedShipping?.company || formik?.values?.shippingAddress?.company || '',
        streetName:
          validateShipping?.address?.streetName?.toLowerCase().replace(/\b\w/g, (char: any) => char?.toUpperCase()) ||
          '',
        streetNumber: validateShipping?.address?.streetNumber || '',
        city: validateShipping?.address?.city?.toLowerCase().replace(/\b\w/g, (char: any) => char?.toUpperCase()) || '',
        state: validateShipping?.address?.state || '',
        postalCode: validateShipping?.address?.postalCode || '',
        country: validateShipping?.address?.country || '',
        phone: selectedShipping?.phone || formik?.values?.shippingAddress?.phone || '',
        extension: selectedShipping?.extension || formik?.values?.shippingAddress?.extension || '',
      },
    ],
    billingAddress: [
      {
        addressId: selectedBilling?.addressId || formik?.values?.addressId || '',
        firstName: selectedBilling?.firstName || formik?.values?.billingAddress?.firstName || '',
        lastName: selectedBilling?.lastName || formik?.values?.billingAddress?.lastName || '',
        company: selectedBilling?.company || formik?.values?.billingAddress?.company || '',
        streetName:
          validateBilling?.address?.streetName?.toLowerCase().replace(/\b\w/g, (char: any) => char?.toUpperCase()) ||
          '',
        streetNumber: validateBilling?.address?.streetNumber || '',
        city: validateBilling?.address?.city?.toLowerCase().replace(/\b\w/g, (char: any) => char?.toUpperCase()) || '',
        state: validateBilling?.address?.state || '',
        postalCode: validateBilling?.address?.postalCode || '',
        country: validateBilling?.address?.country || '',
        phone: selectedBilling?.phone || formik?.values?.billingAddress?.phone || '',
        extension: selectedBilling?.extension || formik?.values?.billingAddress?.extension || '',
      },
    ],
  };
  const addressData: ValidAddressData = {
    shippingAddress: [
      {
        addressId: selectedShipping?.addressId || formik?.values?.addressId || '',
        firstName: selectedShipping?.firstName || formik?.values?.shippingAddress?.firstName || '',
        lastName: selectedShipping?.lastName || formik?.values?.shippingAddress?.lastName || '',
        company: selectedShipping?.company || formik?.values?.shippingAddress?.company || '',
        streetName: selectedShipping?.streetName || formik?.values?.shippingAddress?.streetName || '',
        streetNumber: selectedShipping?.streetNumber || formik?.values?.shippingAddress?.streetNumber || '',
        city: selectedShipping?.city || formik?.values?.shippingAddress?.city || '',
        state: selectedShipping?.state || formik?.values?.shippingAddress?.state || '',
        postalCode: selectedShipping?.postalCode || formik?.values?.shippingAddress?.postalCode || '',
        country: selectedShipping?.country || formik?.values?.shippingAddress?.country || '',
        phone: selectedShipping?.phone || formik?.values?.shippingAddress?.phone || '',
        extension: selectedShipping?.extension || formik?.values?.shippingAddress?.extension || '',
      },
    ],
    billingAddress: [
      {
        addressId: selectedBilling?.addressId || formik?.values?.addressId || '',
        firstName: selectedBilling?.firstName || formik?.values?.billingAddress?.firstName || '',
        lastName: selectedBilling?.lastName || formik?.values?.billingAddress?.lastName || '',
        company: selectedBilling?.company || formik?.values?.billingAddress?.company || '',
        streetName: selectedBilling?.streetName || formik?.values?.billingAddress?.streetName || '',
        streetNumber: selectedBilling?.streetNumber || formik?.values?.billingAddress?.streetNumber || '',
        city: selectedBilling?.city || formik?.values?.billingAddress?.city || '',
        state: selectedBilling?.state || formik?.values?.billingAddress?.state || '',
        postalCode: selectedBilling?.postalCode || formik?.values?.billingAddress?.postalCode || '',
        country: selectedBilling?.country || formik?.values?.billingAddress?.country || '',
        phone: selectedBilling?.phone || formik?.values?.billingAddress?.phone || '',
        extension: selectedBilling?.extension || formik?.values?.billingAddress?.extension || '',
      },
    ],
  };

  const [selectedShippingAddress, setSelectedShippingAddress] = useState(validAddressData?.shippingAddress[0]);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(validAddressData?.billingAddress[0]);

  useEffect(() => {
    setSelectedShippingAddress(validAddressData?.shippingAddress[0]);
    setSelectedBillingAddress(validAddressData?.billingAddress[0]);
  }, [selectedShipping, validateShipping, selectedBilling, validateBilling]);

  const handleUpdateAddress = async () => {
    setSelectedAddress(selectedShippingAddress);
    setBillingAddress(selectedBillingAddress);
    onClose();
    setloading(true);
    try {
      const cart = await updateCart({
        account: { email: account?.email || formik?.values?.email },
        shipping: StringHelpers.phoneExtensionMerger(selectedShippingAddress),
        billing: isSameAddress
          ? StringHelpers.phoneExtensionMerger(selectedShippingAddress)
          : StringHelpers.phoneExtensionMerger(selectedBillingAddress),
      });
      gaAddShippingInfo(cart);
      listrakSCAEvent(cart, account);
      router.push(routerURL.checkoutPayment);
    } catch (error) {
      showNotification(SERVER_ERROR, 'error');
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={onClose}>
        <>
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="absolute inset-0">
                {/* eslint-disable */}
                <div className="absolute top-1/2 left-1/2 h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-6 px-4 dark:bg-primary-200 sm:px-6 md:w-[800px] lg:h-auto lg:px-8">
                  <section className="flex justify-between">
                    <h1 className="mx-auto mb-9 w-max text-center text-sm font-bold leading-[29px] text-sol-400 after:mx-auto after:mt-4 after:block after:h-[1px] after:w-[150px] after:bg-sol-600 after:content-[''] lg:text-lg">
                      {/* {modalHeading} */}
                      {mapLocaleToMeaningfulFormat(router.locale).suggestAddressHeading}
                    </h1>
                    <section className="pt-2">
                      <button type="button" onClick={onClose}>
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  {!isShippingValid &&
                    (!validateShipping?.isValid ? (
                      <>
                        <section className="my-5">
                          <p className="text-2xl font-medium uppercase leading-[17px] text-sol-300">
                            {/* {modalContent} */}
                            {mapLocaleToMeaningfulFormat(router.locale).shippingAddress}
                          </p>
                        </section>
                        <section className="mt-5 grid grid-cols-1 lg:grid-cols-2">
                          <section>
                            <section className="mb-2">
                              <input
                                type="radio"
                                id="shippingAddress"
                                name="shippingAddress"
                                className="mr-2"
                                onClick={() => setSelectedShippingAddress(addressData?.shippingAddress[0])}
                                defaultChecked
                              />
                              <label htmlFor="validAddressData">
                                <h2 className="text-sm font-semibold">
                                  {mapLocaleToMeaningfulFormat(router.locale).useEnteredAddress}
                                </h2>
                              </label>
                            </section>
                            <ul className="mt-2">
                              <li>
                                <p>
                                  {addressData?.shippingAddress[0]?.firstName}{' '}
                                  {addressData?.shippingAddress[0]?.lastName}
                                </p>
                                <p>
                                  {addressData?.shippingAddress[0]?.streetNumber}{' '}
                                  {addressData?.shippingAddress[0]?.streetName}
                                </p>
                                <p>
                                  {addressData?.shippingAddress[0]?.city} {addressData?.shippingAddress[0]?.state}{' '}
                                  {addressData?.shippingAddress[0]?.postalCode}
                                </p>
                                <p>{addressData?.shippingAddress[0]?.country}</p>
                                <p>{addressData?.shippingAddress[0]?.phone}</p>
                              </li>
                            </ul>
                          </section>
                        </section>
                      </>
                    ) : (
                      <>
                        <section className="my-5">
                          <p className="text-2xl font-medium uppercase leading-[17px] text-sol-300">
                            {/* {modalContent} */}
                            {mapLocaleToMeaningfulFormat(router.locale).shippingAddress}
                          </p>
                        </section>
                        <section className="mt-5 grid grid-cols-1 lg:grid-cols-2">
                          <section>
                            <section className="mb-2">
                              <input
                                type="radio"
                                id="validShippingAddress"
                                name="shippingAddress"
                                className="mr-2"
                                onClick={() => setSelectedShippingAddress(validAddressData?.shippingAddress[0])}
                                defaultChecked
                              />
                              <label htmlFor="addressData">
                                <h2 className="text-sm font-semibold">
                                  {mapLocaleToMeaningfulFormat(router.locale).useCorrectedAddress}
                                </h2>
                              </label>
                            </section>
                            <ul className="mt-2">
                              <li>
                                <p>
                                  {validAddressData?.shippingAddress[0]?.firstName}{' '}
                                  {validAddressData?.shippingAddress[0]?.lastName}
                                </p>
                                <p>
                                  {validAddressData?.shippingAddress[0]?.streetNumber}{' '}
                                  {validAddressData?.shippingAddress[0]?.streetName}
                                </p>
                                <p>
                                  {validAddressData?.shippingAddress[0]?.city}{' '}
                                  {validAddressData?.shippingAddress[0]?.state}{' '}
                                  {validAddressData?.shippingAddress[0]?.postalCode}
                                </p>
                                <p>{validAddressData?.shippingAddress[0]?.country}</p>
                                <p>
                                  {StringHelpers.phoneDisplayFormat(
                                    validAddressData?.shippingAddress[0]?.extension
                                      ? StringHelpers.phoneExtensionMerger(validAddressData?.shippingAddress[0])?.phone
                                      : validAddressData?.shippingAddress[0]?.phone,
                                  )}
                                </p>
                              </li>
                            </ul>
                          </section>
                          <section>
                            <section className="mb-2">
                              <input
                                type="radio"
                                id="shippingAddress"
                                name="shippingAddress"
                                className="mr-2"
                                onClick={() => setSelectedShippingAddress(addressData?.shippingAddress[0])}
                              />
                              <label htmlFor="validAddressData">
                                <h2 className="text-sm font-semibold">
                                  {mapLocaleToMeaningfulFormat(router.locale).useEnteredAddress}
                                </h2>
                              </label>
                            </section>
                            <ul className="mt-2">
                              <li>
                                <p>
                                  {addressData?.shippingAddress[0]?.firstName}{' '}
                                  {addressData?.shippingAddress[0]?.lastName}
                                </p>
                                <p>
                                  {addressData?.shippingAddress[0]?.streetNumber}{' '}
                                  {addressData?.shippingAddress[0]?.streetName}
                                </p>
                                <p>
                                  {addressData?.shippingAddress[0]?.city} {addressData?.shippingAddress[0]?.state}{' '}
                                  {addressData?.shippingAddress[0]?.postalCode}
                                </p>
                                <p>{addressData?.shippingAddress[0]?.country}</p>
                                <p>
                                  {StringHelpers.phoneDisplayFormat(
                                    addressData?.shippingAddress[0]?.extension
                                      ? StringHelpers.phoneExtensionMerger(addressData?.shippingAddress[0])?.phone
                                      : addressData?.shippingAddress[0]?.phone,
                                  )}
                                </p>
                              </li>
                            </ul>
                          </section>
                        </section>
                      </>
                    ))}
                  {!isBillingValid &&
                    !isSameAddress &&
                    (!validateBilling?.isValid ? (
                      <>
                        <section className="my-5">
                          <p className="text-2xl font-medium uppercase leading-[17px] text-sol-300">
                            {/* {modalContent} */}
                            {mapLocaleToMeaningfulFormat(router.locale).billingAddress}
                          </p>
                        </section>
                        <section className="mt-5 grid grid-cols-1 lg:grid-cols-2">
                          <section>
                            <section className="mb-2">
                              <input
                                type="radio"
                                id="billingAddress"
                                name="billingAddress"
                                className="mr-2"
                                onClick={() => setSelectedBillingAddress(addressData?.billingAddress[0])}
                                defaultChecked
                              />
                              <label htmlFor="validAddressData">
                                <h2 className="text-sm font-semibold">
                                  {mapLocaleToMeaningfulFormat(router.locale).useEnteredAddress}
                                </h2>
                              </label>
                            </section>
                            <ul className="mt-2">
                              <li>
                                <p>
                                  {addressData?.billingAddress[0]?.firstName} {addressData?.billingAddress[0]?.lastName}
                                </p>
                                <p>
                                  {addressData?.billingAddress[0]?.streetNumber}{' '}
                                  {addressData?.billingAddress[0]?.streetName}
                                </p>
                                <p>
                                  {addressData?.billingAddress[0]?.city} {addressData?.billingAddress[0]?.state}{' '}
                                  {addressData?.billingAddress[0]?.postalCode}
                                </p>
                                <p>{addressData?.billingAddress[0]?.country}</p>
                                <p>{addressData?.billingAddress[0]?.phone}</p>
                              </li>
                            </ul>
                          </section>
                        </section>
                      </>
                    ) : (
                      <>
                        <section className="my-5">
                          <p className="text-2xl font-medium uppercase leading-[17px] text-sol-300">
                            {/* {modalContent} */}
                            {mapLocaleToMeaningfulFormat(router.locale).billingAddress}
                          </p>
                        </section>
                        <section className="mt-5 grid grid-cols-1 lg:grid-cols-2">
                          <section>
                            <section className="mb-2">
                              <input
                                type="radio"
                                id="validBillingAddress"
                                name="billingAddress"
                                className="mr-2"
                                onClick={() => setSelectedBillingAddress(validAddressData?.billingAddress[0])}
                                defaultChecked
                              />
                              <label htmlFor="addressData">
                                <h2 className="text-sm font-semibold">
                                  {mapLocaleToMeaningfulFormat(router.locale).useCorrectedAddress}
                                </h2>
                              </label>
                            </section>
                            <ul className="mt-2">
                              <li>
                                <p>
                                  {validAddressData?.billingAddress[0]?.firstName}{' '}
                                  {validAddressData?.billingAddress[0]?.lastName}
                                </p>
                                <p>
                                  {validAddressData?.billingAddress[0]?.streetNumber}{' '}
                                  {validAddressData?.billingAddress[0]?.streetName}
                                </p>
                                <p>
                                  {validAddressData?.billingAddress[0]?.city}{' '}
                                  {validAddressData?.billingAddress[0]?.state}{' '}
                                  {validAddressData?.billingAddress[0]?.postalCode}
                                </p>
                                <p>{validAddressData?.billingAddress[0]?.country}</p>
                                <p>
                                  {StringHelpers.phoneDisplayFormat(
                                    validAddressData?.billingAddress[0]?.extension
                                      ? StringHelpers.phoneExtensionMerger(validAddressData?.billingAddress[0])?.phone
                                      : validAddressData?.billingAddress[0]?.phone,
                                  )}
                                </p>
                              </li>
                            </ul>
                          </section>
                          <section>
                            <section className="mb-2">
                              <input
                                type="radio"
                                id="billingAddress"
                                name="billingAddress"
                                className="mr-2"
                                onClick={() => setSelectedBillingAddress(addressData?.billingAddress[0])}
                              />
                              <label htmlFor="validAddressData">
                                <h2 className="text-sm font-semibold">
                                  {mapLocaleToMeaningfulFormat(router.locale).useEnteredAddress}
                                </h2>
                              </label>
                            </section>
                            <ul className="mt-2">
                              <li>
                                <p>
                                  {addressData?.billingAddress[0]?.firstName} {addressData?.billingAddress[0]?.lastName}
                                </p>
                                <p>
                                  {addressData?.billingAddress[0]?.streetNumber}{' '}
                                  {addressData?.billingAddress[0]?.streetName}
                                </p>
                                <p>
                                  {addressData?.billingAddress[0]?.city} {addressData?.billingAddress[0]?.state}{' '}
                                  {addressData?.billingAddress[0]?.postalCode}
                                </p>
                                <p>{addressData?.billingAddress[0]?.country}</p>
                                <p>
                                  {StringHelpers.phoneDisplayFormat(
                                    validAddressData?.billingAddress[0]?.extension
                                      ? StringHelpers.phoneExtensionMerger(validAddressData?.billingAddress[0])?.phone
                                      : validAddressData?.billingAddress[0]?.phone,
                                  )}
                                </p>
                              </li>
                            </ul>
                          </section>
                        </section>
                      </>
                    ))}

                  <section className="mt-5 flex flex-col gap-2 lg:flex-row lg:gap-[11.75rem]">
                    <button type="button" onClick={handleUpdateAddress} className="btn-primary-small">
                      {mapLocaleToMeaningfulFormat(router.locale).confirmAddress}
                    </button>
                    {formik?.values?.email ? (
                      <button
                        type="button"
                        onClick={() => {
                          onClose();
                        }}
                        className="btn-primary-small"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).editAddress}
                      </button>
                    ) : null}
                  </section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </>
      </Dialog>
    </Transition.Root>
  );
}

export default SuggestAddress;
