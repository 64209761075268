import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { PromotionalBanner } from 'components/sol-ui/promotional-banner';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getAlignment, getMobileAlignment } from 'helpers/utils/banner';
import { handleSwipeHeroCarousel } from 'helpers/utils/carouselSwipe';
import {
  AMPLIENCE_IMAGE_URL_FORMAT,
  BANNER_IMAGE_SIZE_DESKTOP,
  BANNER_IMAGE_SIZE_MOBILE,
} from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { desktop, tabletLandscape } from 'helpers/utils/screensizes';
import ForwardIcon from '../../../icons/sol/forward';
const HeroCarouselContentBlock = ({ content, sliderInterval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDesktop] = useMediaQuery(desktop);
  const [isTabletLandscape] = useMediaQuery(tabletLandscape);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [heroCarouselMarginTop, setHeroCarouselMarginTop] = useState('mt');
  const [promotionalBannerHeight, setPromotionalBannerHeight] = useState(null);
  const [hover, setHover] = useState(false);

  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const handleTouchEnd = (e) => {
    setTouchEndX(e?.changedTouches[0]?.clientX);
    handleSwipeHeroCarousel(touchStartX, e.changedTouches[0].clientX, currentIndex, content, setCurrentIndex);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (promotionalBannerHeight !== null) {
        setScrollPosition(window.scrollY);
        if (window.scrollY > 0) {
          setHeroCarouselMarginTop('mt-[10px]');
        } else {
          setHeroCarouselMarginTop(' ');
        }
      }
    };
    const handleResize = () => {
      setPromotionalBannerHeight(document.getElementById('promotionalBanner').offsetHeight);
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [promotionalBannerHeight]);

  const handleClicknext = () => {
    setCurrentIndex(currentIndex == content?.length - 1 ? 0 : currentIndex + 1);
  };

  useEffect(() => {
    const handleAutoplay = setInterval(!hover && sliderInterval && handleClicknext, sliderInterval * 1000);
    return () => clearInterval(handleAutoplay);
  }, [handleClicknext]);

  return (
    <>
      <section
        id="promotionalBanner"
        className={`hero-carousel relative mb-8 flex w-full items-center justify-center ${heroCarouselMarginTop} lg:mt-0 `}
        onTouchStart={(e) => setTouchStartX(e.touches[0].clientX)}
        onTouchMove={(e) => setTouchEndX(e.touches[0].clientX)}
        onTouchEnd={handleTouchEnd}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ul className="min-h-[420px] w-full xl:min-h-[520px]  2xl:min-h-[620px]">
          <li className="relative min-h-[420px] w-full xl:min-h-[520px] 2xl:min-h-[620px]" tabIndex={0}>
            {content[currentIndex]?.desktopMedia?.__typename == 'ImageNode' ? (
              <Link href={content[currentIndex]?.ctaLink}>
                {isTabletLandscape ? (
                  <Image
                    loader={({ src }) => src}
                    src={`${format(AMPLIENCE_IMAGE_URL_FORMAT, [
                      content[currentIndex]?.desktopMedia?.url,
                      BANNER_IMAGE_SIZE_DESKTOP,
                    ])}`}
                    width="1900"
                    height="819"
                    sizes="100vw"
                    className="cursor-pointer"
                    alt={content[currentIndex]?.alt}
                    priority={true}
                  />
                ) : (
                  <Image
                    loader={({ src }) => src}
                    src={`${format(AMPLIENCE_IMAGE_URL_FORMAT, [
                      content[currentIndex]?.mobileMedia?.url,
                      BANNER_IMAGE_SIZE_MOBILE,
                    ])}`}
                    width="780"
                    height="1300"
                    sizes="100vw"
                    className="cursor-pointer"
                    alt={content[currentIndex]?.alt}
                    priority={true}
                  />
                )}
              </Link>
            ) : (
              <>
                {isDesktop ? (
                  <>
                    <video
                      className="w-full"
                      autoPlay
                      loop
                      muted
                      poster={`https://${content[currentIndex]?.desktopMedia?.defaultHost}/v/${content[currentIndex]?.desktopMedia?.endpoint}/${content[currentIndex]?.desktopMedia?.name}`}
                      src={`https://${content[currentIndex]?.desktopMedia?.defaultHost}/v/${content[currentIndex]?.desktopMedia?.endpoint}/${content[currentIndex]?.desktopMedia?.name}/mp4_720p`}
                    ></video>
                  </>
                ) : (
                  <>
                    <video
                      className="w-full"
                      autoPlay
                      loop
                      muted
                      poster={`https://${content[currentIndex]?.mobileMedia?.defaultHost}/v/${content[currentIndex]?.mobileMedia?.endpoint}/${content[currentIndex]?.mobileMedia?.name}`}
                      src={`https://${content[currentIndex]?.mobileMedia?.defaultHost}/v/${content[currentIndex]?.mobileMedia?.endpoint}/${content[currentIndex]?.mobileMedia?.name}/mp4_720p`}
                    ></video>
                  </>
                )}
              </>
            )}
          </li>
        </ul>

        {content[currentIndex]?.heading && (
          <div
            className={`absolute flex w-full ${
              isDesktop
                ? getAlignment(content[currentIndex]?.alignment)
                : getMobileAlignment(content[currentIndex]?.mobileAlignment)
            } `}
          >
            <div className="m-12 flex flex-col items-center justify-center gap-3 bg-[rgb(0,0,0,0.4)] p-10 text-white">
              <p className="font-bold">{content[currentIndex]?.subHeading}</p>
              <p className="text-3xl">{content[currentIndex]?.heading}</p>
              <Link href={content[currentIndex]?.ctaLink}>
                <a className="cursor-pointer bg-[rgb(60,60,60)] p-4 hover:bg-black-100">
                  {content[currentIndex]?.ctaLinkText}
                </a>
              </Link>
            </div>
          </div>
        )}

        {content?.length > 1 && (
          <div className="absolute flex w-full items-center justify-between">
            <button
              type="button"
              aria-label="Previous Header Carousel Button"
              id="previousHeaderCarouselButton"
              onClick={() => setCurrentIndex(currentIndex == 0 ? content?.length - 1 : currentIndex - 1)}
              className="flex cursor-pointer items-center justify-center rounded-r-md bg-[#ffffff41] p-3"
            >
              <ForwardIcon className={'rotate-180 fill-black-100'} />
            </button>
            <button
              type="button"
              aria-label="Next Header Carousel Button"
              id="nextHeaderCarouselButton"
              onClick={() => setCurrentIndex(currentIndex == content?.length - 1 ? 0 : currentIndex + 1)}
              className="flex w-max cursor-pointer items-center justify-center rounded-l-md bg-[#ffffff44] p-3"
            >
              <ForwardIcon className={' fill-black-100'} />
            </button>
          </div>
        )}

        {content?.length > 1 && (
          <div className="absolute bottom-2 flex w-max items-center justify-center gap-[6px] rounded-2xl bg-[#ffffff44] p-[6px]">
            {content?.map((c, index) => (
              <span
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={
                  index == currentIndex
                    ? 'h-3 w-3 cursor-pointer rounded-2xl bg-black-100'
                    : 'h-3 w-3 cursor-pointer rounded-2xl bg-white'
                }
              ></span>
            ))}
          </div>
        )}
      </section>
    </>
  );
};

export default HeroCarouselContentBlock;
