declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaRemoveFromCart = (cart: any, lineItem: any) => {
  const dataLayer = window?.dataLayer;
  const variant = cart.lineItems.find((item: any) => {
    if (item === lineItem) {
      return item;
    }
  });

  try {
    const removeFromCartObj = {
      currency: variant?.totalPrice?.currencyCode,
      value: variant?.totalPrice?.centAmount / 10 ** variant?.totalPrice?.fractionDigits,
      items: [
        {
          item_id: variant?.variant?.sku,
          item_name: variant?.name,
          index: variant?.variant?.sku,
          item_variant: variant?.variant?.attributes?.collection,
          price:
            variant?.variant?.discountedPrice?.centAmount / 10 ** variant?.variant?.price?.fractionDigits ||
            variant?.variant?.price?.centAmount / 10 ** variant?.variant?.price?.fractionDigits,
          quantity: variant?.count,
        },
      ],
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: removeFromCartObj,
    });
  } catch (error) {
    console.log('ga4 remove from cart event fail');
  }
};
