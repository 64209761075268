declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const gaAddToCart = (product: any, variant: any, quantity: any) => {
  const dataLayer = window?.dataLayer;
  try {
    const addToCartObj = {
      currency: variant?.price?.currencyCode,
      value:
        (variant?.discountedPrice?.centAmount / 10 ** variant?.price?.fractionDigits) * quantity ||
        (variant?.price?.centAmount / 10 ** variant?.price?.fractionDigits) * quantity,
      items: [
        {
          item_id: variant?.sku,
          item_name: product?.name,
          index: variant?.sku,
          item_variant: variant?.attributes?.collection,
          price:
            variant?.discountedPrice?.centAmount / 10 ** variant?.price?.fractionDigits ||
            variant?.price?.centAmount / 10 ** variant?.price?.fractionDigits,
          quantity: quantity,
        },
      ],
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'add_to_cart',
      ecommerce: addToCartObj,
    });
    return;
  } catch (error) {
    console.log('ga4 add to cart event fail');
  }
};
