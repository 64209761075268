import { useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Field, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import AmexCard from 'components/icons/sol/amex';
import CreditCardIcon from 'components/icons/sol/credit-card-icon';
import DiscoverCard from 'components/icons/sol/discoverIcon';
import MasterCard from 'components/icons/sol/mastercard';
import VisaCardIcon from 'components/icons/sol/visa';
import Kount from 'components/sol-ui/kount';
import Spinner from 'components/sol-ui/notification/spinner';
import AuthorizeNetScript from 'components/sol-ui/payment/authorizeNet';
import ReCaptcha from 'components/sol-ui/recaptcha';
import { PAYMENT_TOKEN, CARD_NAME } from 'helpers/constants/localStorage';
import { CART_PAYMENT_UPDATED, INVALID_CREDIT_CARD, WRONG_CREDIT_CARD } from 'helpers/constants/messages';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { useNotification } from 'helpers/notification/notificationContext';
import { StringHelpers } from 'helpers/stringHelpers';
import Accept from 'helpers/utils/authorize-net';
import { gaAddPaymentInfo } from 'helpers/utils/ga4-events/addPaymentInfo';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import validationSchema from 'helpers/utils/validationSchema/checkout-payment';
import { useConfig, useCart } from 'frontastic';

type CreditCardForm = {
  creditCardNumber: string;
  expirationDate: string;
  cvv: string;
};

const CheckoutGuestPayment = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);

  const { data: config } = useConfig();
  const { data: cart } = useCart();
  //global notification
  const { showNotification } = useNotification();
  const { setItem } = useLocalStorage();
  const creditCardInitialValues: CreditCardForm = {
    creditCardNumber: '',
    expirationDate: '',
    cvv: '',
  };
  const handleSubmit = async (data: CreditCardForm) => {
    const cardNumber = data.creditCardNumber.replace(/\s/g, '');
    if (!StringHelpers.checkValidCreditCard(cardNumber)) {
      showNotification(INVALID_CREDIT_CARD, 'error');
    } else {
      if (isReCaptchaVerified) {
        setLoading(true);
        const [month, year] = data.expirationDate.split('/');

        const authData = {
          apiLoginID: config?.authorizeNet?.apiLoginKey,
          clientKey: config?.authorizeNet?.clientKey,
        };
        let result = null;
        try {
          result = await Accept.dispatchData({
            authData,
            cardData: {
              cardNumber: data.creditCardNumber.replace(/\s/g, ''),
              month,
              year,
            },
          });
        } catch (error) {
          // showNotification(error, 'error');
          console.log('error', error);
          showNotification(WRONG_CREDIT_CARD, 'error');
        }
        if (result && result?.opaqueData?.dataValue) {
          try {
            setItem(PAYMENT_TOKEN, result?.opaqueData?.dataValue);
            setItem(CARD_NAME, `${data.creditCardNumber.slice(-4)}`);
            //showNotification(CART_PAYMENT_UPDATED, 'success');
            gaAddPaymentInfo(cart);
            router.push(routerURL.checkoutReview);
          } catch (error) {
            showNotification(error.toString(), 'error');
          }
        } else {
          showNotification(WRONG_CREDIT_CARD, 'error');
        }
        setLoading(false);
      } else {
        setReCaptchaError(true);
      }
    }
  };

  const Input = styled(InputMask)``;
  return (
    <>
      <AuthorizeNetScript />
      <div>
        <section className="mt-5 max-w-screen-8xl">
          <Kount sessionId={cart?.cartId?.substring(0, 32)} config={config?.kount} />
          <Formik
            initialValues={creditCardInitialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formik) => (
              <Form>
                <section className="max-w-screen-8xl rounded-lg">
                  <h1 className="text-lg font-semibold text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).paymentMethod}
                  </h1>
                  <section className="grid grid-cols-1 gap-y-5">
                    <section className=" mt-5 w-full">
                      <label
                        htmlFor="creditCardNumber"
                        className="requiredLabel mb-1 flex text-sm font-semibold leading-[17px] text-sol-300"
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).creditCardNumber}
                        <span className="ml-2"></span>{' '}
                      </label>
                      <section className="flex flex-col gap-2 md:flex-row md:items-center md:gap-5">
                        <section className="relative md:w-[434px]">
                          <Field
                            type="text"
                            name="creditCardNumber"
                            id="creditCardNumber"
                            onBlur={formik?.handleBlur}
                            className={`input-text-primary !w-full pl-10 ${
                              formik?.errors?.creditCardNumber && formik?.touched?.creditCardNumber
                                ? '!border-red-600'
                                : ''
                            }`}
                          />
                          {formik?.errors?.creditCardNumber && formik?.touched?.creditCardNumber && (
                            <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                              {formik?.errors?.creditCardNumber}
                            </p>
                          )}
                          <CreditCardIcon className="absolute left-[10px] top-[13px] fill-sol-300" />
                        </section>
                        <section className="flex items-start justify-start gap-3 lg:mb-4 lg:items-center lg:justify-center">
                          <AmexCard />
                          <DiscoverCard />
                          <MasterCard />
                          <VisaCardIcon />
                        </section>
                      </section>
                    </section>

                    <section className="flex !w-full gap-x-4">
                      <section className="w-full md:w-[204px]">
                        <label
                          htmlFor="expirationDate"
                          className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).expirationDate}
                          <span className="ml-1"></span>{' '}
                        </label>
                        <Field
                          name="expirationDate"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                          value={formik?.values?.expirationDate ?? creditCardInitialValues?.expirationDate}
                        >
                          {({ form: { errors, touched } }) => (
                            <Input
                              id="expirationDate"
                              type="text"
                              mask={'99/99'}
                              maskChar={null}
                              placeholder="mm/yy"
                              onChange={formik?.handleChange}
                              onBlur={formik?.handleBlur}
                              value={formik?.values?.expirationDate ?? creditCardInitialValues?.expirationDate}
                              className={`input-text-primary mt-1 !w-full ${
                                errors?.expirationDate && touched?.expirationDate ? '!border-red-600' : ''
                              }`}
                            />
                          )}
                        </Field>
                        {formik?.errors?.expirationDate && formik?.touched?.expirationDate && (
                          <p className="text-sm font-normal leading-[17px] text-[#DA0F0F]">
                            {formik?.errors?.expirationDate}
                          </p>
                        )}
                      </section>
                      <section className="w-[142px]">
                        <label
                          htmlFor="extension"
                          className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).cVV}
                          <span className="mb-4 ml-1"></span>{' '}
                        </label>

                        <Field
                          type="text"
                          name="cvv"
                          id="cvv"
                          onBlur={formik?.handleBlur}
                          className={`input-text-primary mt-1 !w-full pl-3 ${
                            formik?.errors?.cvv && formik?.touched?.cvv ? '!border-red-600' : ''
                          }`}
                        />
                        {formik?.errors?.cvv && formik?.touched?.cvv && (
                          <p className="!w-full text-sm font-normal leading-[17px] text-[#DA0F0F]">
                            {formik?.errors?.cvv}
                          </p>
                        )}
                      </section>
                    </section>
                  </section>
                </section>
                <ReCaptcha setIsReCaptchaVerified={setIsReCaptchaVerified} setReCaptchaError={setReCaptchaError} />
                {reCaptchaError && (
                  <span className="text-xs font-semibold text-red-500">
                    {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                  </span>
                )}
                <section className="mt-5 flex flex-col items-center gap-0 md:flex-row md:gap-6">
                  <button
                    type="submit"
                    id="continueGuestPayment"
                    className="btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto"
                  >
                    {loading ? (
                      <section className="flex items-center justify-center gap-3">
                        {' '}
                        <Spinner />
                        <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                      </section>
                    ) : (
                      mapLocaleToMeaningfulFormat(router.locale).continueToReview
                    )}
                  </button>
                </section>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </>
  );
};

export default CheckoutGuestPayment;
