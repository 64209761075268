import React, { useState, useEffect, Fragment } from 'react';
import { useRouter } from 'next/router';
import { Listbox, Transition } from '@headlessui/react';
import { Card, CardRequest } from '@Types/account/AuthorizeNetAccount';
import { Field, Form, Formik } from 'formik';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import * as Yup from 'yup';
import CardIcon from 'components/sol-ui/account/credit-card/card-icon';
import CreditCard from 'components/sol-ui/account/modal/credit-card';
import Kount from 'components/sol-ui/kount';
import Spinner from 'components/sol-ui/notification/spinner';
import AuthorizeNetScript from 'components/sol-ui/payment/authorizeNet';
import ReCaptcha from 'components/sol-ui/recaptcha';
import { PAYMENT_PROFILE } from 'helpers/constants/localStorage';
import { SERVER_ERROR, CART_PAYMENT_UPDATED } from 'helpers/constants/messages';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { useNotification } from 'helpers/notification/notificationContext';
import { StringHelpers } from 'helpers/stringHelpers';
import { gaAddPaymentInfo } from 'helpers/utils/ga4-events/addPaymentInfo';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { useCart, useAuthorizeNet, useConfig } from 'frontastic';
import { setDefaultCreditCard } from 'frontastic/actions/authorize-net';
const CheckoutAuthPayment = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { data: config } = useConfig();
  const { data: cart } = useCart();
  const { data: cards, addCard } = useAuthorizeNet();
  const [showCardFilterDropdown, setShowCardFilterDropdown] = useState(undefined);
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState(false);
  const cvvValue = { cvv: '' };
  const [enablePaymentButton, setEnablePaymentButton] = useState(false);
  const [filterArrow, setFilterArrow] = useState(false);
  const [AddCardModalOpen, setAddCardModalOpen] = useState(false);
  const { getItem, removeItem } = useLocalStorage();
  useEffect(() => {
    setAddCardModalOpen(cards?.length === 0);
    if (cards && cards?.length > 0 && !showCardFilterDropdown) {
      const savedPaymentProfile = getItem(PAYMENT_PROFILE);
      let selectedProfile: Card = cards?.find((card) => card?.isDefault);
      if (savedPaymentProfile === undefined || !savedPaymentProfile) {
        selectedProfile = cards?.find((card) => card?.isDefault);
      }
      if (savedPaymentProfile) {
        const savedProfile = cards?.find((card) => card?.customerPaymentProfileId === savedPaymentProfile);
        if (savedProfile || savedProfile !== undefined) {
          selectedProfile = savedProfile;
        }
      }
      setShowCardFilterDropdown(selectedProfile);
      setEnablePaymentButton(true);
    }
  }, [showCardFilterDropdown]);

  useEffect(() => {
    const savedPaymentProfile = getItem(PAYMENT_PROFILE);
    if (cards && cards?.length && !savedPaymentProfile) {
      setShowCardFilterDropdown(cards?.find((card) => card?.isDefault));
      setEnablePaymentButton(true);
    }
  }, [cards]);
  //global notification
  const { showNotification } = useNotification();
  const { setItem } = useLocalStorage();
  const openCreditCardModal = () => {
    setAddCardModalOpen(true);
  };
  const closeCreditCardModal = () => {
    setAddCardModalOpen(false);
  };
  const Input = styled(InputMask)``;
  const handleShareListArrow = () => {
    setFilterArrow(!filterArrow);
  };

  const handleSubmit = async () => {
    if (isReCaptchaVerified) {
      try {
        setItem(PAYMENT_PROFILE, showCardFilterDropdown?.customerPaymentProfileId);
        //showNotification(CART_PAYMENT_UPDATED, 'success');
        gaAddPaymentInfo(cart);
        router.push(routerURL.checkoutReview);
      } catch (error) {
        //showNotification(SERVER_ERROR, 'error');
      }
    } else {
      setReCaptchaError(true);
    }
  };

  const handleAdd = async (request: CardRequest) => {
    setLoading(true);
    if (cards?.length === 0) {
      request.isDefault = true;
    }
    const response = await addCard(request);
    if (response?.customerPaymentProfileId) {
      if (request.isDefault) {
        await setDefaultCreditCard(response?.customerPaymentProfileId);
        removeItem(PAYMENT_PROFILE);
      }
      showNotification(response?.message, 'success');
    } else {
      showNotification(response?.message, 'error');
    }
    setLoading(false);
  };

  const cvvValidationSchema = Yup.object({
    cvv: Yup.string()
      .required('Please enter correct CVV.')
      .matches(/^[0-9]{3,4}$/, 'Must be exactly between 3-4 digits number.'),
  });
  return (
    <>
      <AuthorizeNetScript />
      <div>
        <Kount sessionId={cart?.cartId?.substring(0, 32)} config={config?.kount} />
        <Formik
          initialValues={cvvValue}
          validationSchema={cvvValidationSchema}
          onSubmit={() => {
            enablePaymentButton && handleSubmit();
          }}
        >
          {(formik) => (
            <Form>
              <section className="mt-5 max-w-screen-8xl rounded-lg border p-5">
                <h1 className="text-lg font-semibold text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).paymentMethod}
                </h1>
                {enablePaymentButton && (
                  <>
                    {' '}
                    <section className="mt-5 flex flex-col gap-5 md:flex-row">
                      <section>
                        {cards && cards?.length > 0 && (
                          <>
                            <Listbox value={showCardFilterDropdown} onChange={setShowCardFilterDropdown}>
                              <section className="relative z-[2]">
                                <label htmlFor="card" className="mb-1 block text-sm font-semibold text-sol-300 ">
                                  {mapLocaleToMeaningfulFormat(router.locale).creditCard}
                                </label>
                                <Listbox.Button className="relative w-full cursor-default rounded border bg-white py-2 pl-3 pr-10 text-left md:w-[272px]">
                                  <em className="block truncate text-sm not-italic text-sol-300">
                                    {showCardFilterDropdown !== undefined ? (
                                      <section className="flex items-center gap-3">
                                        {' '}
                                        <CardIcon cardType={showCardFilterDropdown?.cardType || 'default'} />
                                        {StringHelpers.creditCardNumberFormatter(
                                          showCardFilterDropdown?.cardNumber,
                                          router,
                                        )}
                                      </section>
                                    ) : (
                                      <>{mapLocaleToMeaningfulFormat(router.locale).select}</>
                                    )}
                                  </em>
                                  <em className="absolute inset-y-0 right-0 flex items-center not-italic">
                                    {!filterArrow ? (
                                      <section className="my-auto mr-3">
                                        <MdOutlineKeyboardArrowDown
                                          onClick={handleShareListArrow}
                                          className="text-2xl text-sol-300"
                                        />
                                      </section>
                                    ) : (
                                      <section className="my-auto mr-3">
                                        <MdOutlineKeyboardArrowUp
                                          onClick={handleShareListArrow}
                                          className="text-2xl text-sol-300"
                                        />
                                      </section>
                                    )}
                                  </em>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="md:ring-black absolute z-0 max-h-[11.25rem] w-full overflow-y-auto rounded-md bg-white text-base text-sol-300 shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm  md:max-h-24 ">
                                    {cards?.map((card, key) => (
                                      <Listbox.Option
                                        key={key}
                                        className={({ active }) =>
                                          `relative cursor-default select-none  ${active && 'bg-gray-200 text-sol-300'}`
                                        }
                                        value={card}
                                      >
                                        {({ active, selected }) => (
                                          <>
                                            <li
                                              className={`${
                                                active ? 'text-base text-sol-300 sm:text-sm' : 'text-gray-900'
                                              } relative cursor-default select-none py-2 pl-3 pr-9`}
                                            >
                                              <em
                                                className={`${
                                                  selected ? 'font-medium not-italic' : 'font-normal not-italic'
                                                } block truncate not-italic`}
                                              >
                                                <section className="flex items-center gap-3">
                                                  <CardIcon cardType={card?.cardType} />
                                                  <p>
                                                    {StringHelpers.creditCardNumberFormatter(card?.cardNumber, router)}
                                                  </p>
                                                </section>
                                              </em>
                                            </li>
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </section>
                            </Listbox>
                          </>
                        )}
                      </section>
                      <section className="z-[1] rounded-lg">
                        <label
                          htmlFor="extension"
                          className="requiredLabel text-sm font-semibold leading-[17px] text-sol-300"
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).cVV}
                          <span className="mb-4 ml-1"></span>{' '}
                        </label>
                        <section className="w-[91px] ">
                          <Field
                            type="text"
                            name="cvv"
                            id="cvv"
                            onBlur={formik?.handleBlur}
                            className={`input-text-primary !w-full pl-3 ${
                              formik?.errors?.cvv && formik?.touched?.cvv ? '!border-red-600' : ''
                            }`}
                          />
                        </section>
                        {formik?.errors?.cvv && formik?.touched?.cvv && (
                          <p className="md:[214px] !w-full text-sm font-normal text-[#DA0F0F]">{formik?.errors?.cvv}</p>
                        )}
                      </section>
                    </section>
                    <p className="mt-1">{mapLocaleToMeaningfulFormat(router.locale).or}</p>{' '}
                  </>
                )}
                <button
                  type="button"
                  id="addAddress"
                  onClick={openCreditCardModal}
                  className="my-2 text-sm underline underline-offset-1"
                >
                  {mapLocaleToMeaningfulFormat(router.locale).addNewCreditCard}
                </button>
                <CreditCard
                  open={AddCardModalOpen}
                  onClose={closeCreditCardModal}
                  modalHeading={`${mapLocaleToMeaningfulFormat(router.locale).addCreditCard}`}
                  primaryBtnContent={`${mapLocaleToMeaningfulFormat(router.locale).save}`}
                  handleAdd={handleAdd}
                  loading={loading}
                />
              </section>
              <ReCaptcha setIsReCaptchaVerified={setIsReCaptchaVerified} setReCaptchaError={setReCaptchaError} />
              {reCaptchaError && (
                <span className="text-xs font-semibold text-red-500">
                  {mapLocaleToMeaningfulFormat(router.locale).reCaptchaError}
                </span>
              )}
              <section className="mt-4">
                <button
                  type="submit"
                  className={`btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto ${
                    !enablePaymentButton && 'cursor-not-allowed opacity-50'
                  }`}
                >
                  {loading ? (
                    <section className="flex items-center justify-center gap-3">
                      {' '}
                      <Spinner />
                      <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                    </section>
                  ) : (
                    mapLocaleToMeaningfulFormat(router.locale).continueToReview
                  )}
                </button>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CheckoutAuthPayment;
