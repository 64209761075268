declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaSelectItem = (wishlist: any, lineItem: any) => {
  const dataLayer = window?.dataLayer;
  try {
    const selectItemObj = {
      item_list_name: wishlist?.wishlistId,
      item_list_id: wishlist?.name,
      items: [
        {
          item_id: lineItem?.variant?.sku,
          item_name: lineItem?.name,
          index: lineItem?.variant?.sku,
          item_variant: lineItem?.variant?.attributes?.collection,
          price:
            lineItem?.variant?.discountedPrice?.centAmount / 10 ** lineItem?.variant?.price?.fractionDigits ||
            lineItem?.variant?.price?.centAmount / 10 ** lineItem?.variant?.price?.fractionDigits,
          quantity: lineItem?.count,
        },
      ],
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'select_item',
      ecommerce: selectItemObj,
    });
  } catch (error) {
    console.log('ga4 select item event fail');
  }
};
