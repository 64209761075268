declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaViewItemList = (wishlist: any) => {
  const dataLayer = window?.dataLayer;
  try {
    const viewItemListObj = {
      item_list_id: wishlist?.wishlistId,
      item_list_name: wishlist?.name,
      items: [
        wishlist.lineItems.map((lineItem) => {
          return {
            item_id: lineItem.variant.sku,
            item_name: lineItem.name,
            index: lineItem.variant.sku,
            item_variant: lineItem.variant?.attributes?.collection,
            price:
              lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
              lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
            quantity: lineItem.count,
          };
        }),
      ],
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'view_item_list',
      ecommerce: viewItemListObj,
    });
  } catch (error) {
    console.log('ga4 view item list event fail');
  }
};
