import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Money } from '@Types/product/Money';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import Icon from 'components/icons/sol/video-play-icon';
import { PREVIOUS_PATH, WISHLIST_DATA } from 'helpers/constants/localStorage';
import { CART_ITEM_MAX_QTY_REACHED, LINEITEM_ADDED, LINEITEM_ADDED_UNSUCCESSFUL } from 'helpers/constants/messages';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { addedToCartObjectIDs, addedToCartObjectIDsAfterSearch } from 'helpers/hooks/algoliaEvents/algoliaEvents';
import {
  getAlgoliaQueryID,
  getAlgoliaQueryIDForPurchase,
  setAlgoliaQueryIDForPurchase,
} from 'helpers/hooks/algoliaEvents/algoliaUtils';
import { useFormat } from 'helpers/hooks/useFormat';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { listrakSCAEvent } from 'helpers/listrakHelper';
import { useNotification } from 'helpers/notification/notificationContext';
import { StringHelpers } from 'helpers/stringHelpers';
import { handleSwipe } from 'helpers/utils/carouselSwipe';
import { AMPLIENCE_IMAGE_URL_FORMAT, EVENT_LEFT_CLICK, EVENT_RIGHT_CLICK } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import { gaAddToCart } from 'helpers/utils/ga4-events/addToCart';
import { gaViewItem } from 'helpers/utils/ga4-events/viewItem';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import MyGiftRegistry from 'helpers/utils/my-gift-registry';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import { validateCartItemQty } from 'helpers/utils/product';
import { buildLinkProductVariant, buildLinkProductVariantWithSize } from 'helpers/utils/productUrlBuilder';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { triggerMiniCart } from 'helpers/utils/triggerMiniCart';
import NoImageAvailable from 'public/images/No-Image-Available.jpg';
import { useCart, useAccount, useProduct } from 'frontastic';
import { addToWishlist } from 'frontastic/actions/wishlist';
import BulbSelectorModal from './bulb-selector-modal';
import ImageModal from './image-modal';
import Accordion from './optional-accessories';
import VerticalCarousel from './vertical-carousel';
import QuickViewModal from './video-modal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export interface Props {
  product: UIProduct;
  onAddToCart: (variant: Variant, quantity: number) => Promise<void>;
  onAddToWishlist: () => void;
  variant: Variant;
  onChangeVariantIdx: (idx: number) => void;
  quickBuyEnabled?: boolean;
}

export type UIProduct = {
  productId: string;
  name: string;
  variants: Variant[];
  price?: Money;
  images?: UIImage[];
  colors?: UIColor[];
  sizes?: UISize[];
  description: string;
  details?: UIDetail[];
  isOnWishlist?: boolean;
  _url: string;
};
interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}
export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}
export interface UISize {
  label: string;
  key: string;
}
export interface UIFinish {
  label: string;
  key: string;
}
interface UIDetail {
  name: string;
  items: string[];
}
interface ImageGalleryCarouselProps {
  isQuickView?: boolean;
  productData: Product;
  selectedVariant: Variant;
  setSelectedVariant: (variant: Variant) => void;
  optionalAccessory?: any;
  setSelectedCarouselImage?: React.Dispatch<React.SetStateAction<string>>;
  closePlpQuickViewModal?: () => void;
  pdpNavigationLink?: any;
  isClearanceCategory?: boolean;
}

interface SizeSelectorProps {
  sizes: UISize[];
  productData: Product;
  selectedVariant: Variant;
  changeVariant: (variant: Variant) => void;
  availableSizes;
  setAvailableColors;
  isClearanceCategory?: boolean;
}
interface FinishSelectorProps {
  finishes: UIFinish[];
  productData: Product;
  selectedVariant: Variant;
  changeVariant: (variant: Variant) => void;
}
interface ColorSelectorProps {
  colors: UIColor[];
  productData: Product;
  selectedVariant: Variant;
  changeVariant: (variant: Variant) => void;
  isClearanceCategory?: boolean;
  setShowClearanceVariantWarning?: (flag: boolean) => any;
  link?: string;
  setLink?: (newLink: string) => void;
  availableColors;
  setAvailableSizes;
}

const SizeSelector: React.FC<SizeSelectorProps> = ({
  productData,
  sizes,
  selectedVariant,
  changeVariant,
  availableSizes,
  isClearanceCategory,
}) => {
  const router = useRouter();
  const [link, setLink] = useState('');
  const handleVariant = (variant) => {
    const productSlug = productData?.slug;
    if (productSlug && variant) {
      let newLink = '';
      if (variant?.attribute?.size) {
        newLink = buildLinkProductVariantWithSize(
          router?.basePath,
          productSlug,
          variant?.attributes?.actualColor,
          variant?.attributes?.size,
        );
      } else {
        newLink = buildLinkProductVariant(router?.basePath, productSlug, variant?.attributes?.actualColor);
      }

      setLink(newLink);
    }
  };
  const onSizeClick = (size: UISize) => {
    const matchingVariant = productData.variants.find(
      (variant) =>
        variant.attributes?.size === size.key &&
        variant.attributes?.actualColor === selectedVariant?.attributes?.actualColor,
    );
    if (matchingVariant) {
      handleVariant(matchingVariant);
      changeVariant(matchingVariant);
    }
  };
  return (
    <>
      {sizes?.length > 0 && (
        <div className="my-4 flex flex-wrap gap-2">
          {sizes
            .filter((size) => {
              const isDisabled = !productData.variants.some(
                (variant) =>
                  variant.attributes?.size === size.key &&
                  variant.attributes?.actualColor === selectedVariant?.attributes?.actualColor,
              );
              return !isDisabled;
            })
            .sort((a, b) => {
              const extractNumeric = (size) => {
                const numericValue = size.key.match(/\d+/);
                return numericValue ? parseInt(numericValue[0]) : NaN;
              };
              //extract numeric value
              const numericA = extractNumeric(a);
              const numericB = extractNumeric(b);
              //checks the numericB with numericB
              if (!isNaN(numericA) && !isNaN(numericB)) {
                return numericA - numericB;
              }
              //if non numeric values
              // compare the sizes as strings
              return a.key.localeCompare(b.key);
            })
            .map((size, idx) => {
              return (
                <Link href={link} passHref key={idx}>
                  <a
                    onClick={(e) => {
                      if (e.button === EVENT_LEFT_CLICK) {
                        //Left click (0 will be the value)
                        e.preventDefault();
                        onSizeClick(size);
                      }
                    }}
                    onMouseDownCapture={(e) => {
                      if (e.button === EVENT_RIGHT_CLICK) {
                        // Right-click (2 will be the value)
                        e.preventDefault();
                        onSizeClick(size);
                      }
                    }}
                    onTouchStart={(e) => {
                      e.preventDefault();
                      onSizeClick(size);
                    }}
                  >
                    <button
                      key={size.key}
                      className={classNames(
                        'rounded-md border py-2 px-4',
                        selectedVariant?.attributes?.size === size.key
                          ? 'bg-[rgb(34,34,34)]/10 text-sol-300'
                          : 'bg-white',
                        !(
                          availableSizes?.includes(size?.label) ||
                          (selectedVariant?.attributes?.size == size?.label &&
                            selectedVariant?.originalPrice !== undefined)
                        ) && isClearanceCategory
                          ? 'opacity-30'
                          : '',
                      )}
                    >
                      {size.label}
                    </button>
                  </a>
                </Link>
              );
            })}
        </div>
      )}
    </>
  );
};

const FinishSelector: React.FC<FinishSelectorProps> = ({ productData, finishes, selectedVariant, changeVariant }) => {
  const onFinishClick = (finish: UIFinish) => {
    const matchingVariant = productData.variants.find(
      (variant) =>
        variant.attributes?.actualFinish === finish.key &&
        variant.attributes?.actualColor === selectedVariant?.attributes?.actualColor &&
        variant.attributes?.actualSize === selectedVariant?.attributes?.actualSize,
    );
    if (matchingVariant) {
      changeVariant(matchingVariant);
    }
  };

  return (
    <div className="flex flex-wrap gap-2">
      {finishes.map((finish) => {
        const isDisabled = !productData.variants.some(
          (variant) =>
            variant.attributes?.actualFinish === finish.key &&
            variant.attributes?.actualColor === selectedVariant?.attributes?.actualColor &&
            variant.attributes?.actualSize === selectedVariant?.attributes?.actualSize,
        );

        return (
          <button
            key={finish.key}
            onClick={() => onFinishClick(finish)}
            className={classNames(
              'rounded-md border py-2 px-4',
              selectedVariant?.attributes?.actualFinish === finish.key ? 'bg-green-600 text-white' : 'bg-white',
              { 'cursor-not-allowed opacity-50': isDisabled },
            )}
            disabled={isDisabled}
          >
            {finish.label}
          </button>
        );
      })}
    </div>
  );
};

// Will need code later on, Maybe will use in future

// const ColorSelector: React.FC<ColorSelectorProps> = ({ productData, colors, selectedVariant, changeVariant }) => {
//   const onColorPick = (color) => {
//     const matchingVariant = productData.variants.find(
//       (variant) =>
//         variant.attributes?.actualColor === color.key &&
//         variant.attributes?.actualSize === selectedVariant?.attributes?.actualSize,
//     );
//     if (matchingVariant) {
//       changeVariant(matchingVariant);
//     }
//   };

//   return (
//     <>
//       {colors?.length > 0 && (
//         <ul className="color-checkbox-ul my-4 flex flex-wrap justify-center gap-4 px-[6px] pt-[6px]">
//           {colors?.map((item: any, index) => {
//             const isDisabled = !productData.variants.some(
//               (variant) =>
//                 variant.attributes?.actualColor === item.key &&
//                 variant.attributes?.actualSize === selectedVariant?.attributes?.actualSize,
//             );

//             return (
//               <li key={index} className="shrink-0">
//                 <div className="flex items-center">
//                   <input
//                     type="checkbox"
//                     onChange={() => onColorPick(item)}
//                     className={`absolute h-6 w-6  opacity-0 ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
//                     checked={selectedVariant?.attributes?.actualColor === item.key}
//                     disabled={isDisabled}
//                   />
//                   <div
//                     className={`linear-default linear-${item?.label?.toLowerCase()} flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-[50%] ${
//                       isDisabled ? 'cursor-not-allowed opacity-50' : ''
//                     }`}
//                   >
//                     <CheckedIcon className="checked-icon h-2 w-2 cursor-pointer" />
//                   </div>
//                 </div>
//               </li>
//             );
//           })}
//         </ul>
//       )}
//     </>
//   );
// };

const ColorSelector: React.FC<ColorSelectorProps> = ({
  productData,
  colors,
  selectedVariant,
  isClearanceCategory,
  changeVariant,
  setShowClearanceVariantWarning,
  link,
  setLink,
  availableColors,
  setAvailableSizes,
}) => {
  useEffect(() => {
    if (selectedVariant) {
      const tempAvailableSizes = productData?.variants
        ?.filter((variant) => variant?.originalPrice !== undefined)
        .map((variant) => variant?.attributes?.size);
      setAvailableSizes([]?.concat(...tempAvailableSizes));
    }
  }, [selectedVariant]);
  const router = useRouter();
  const onColorPick = (color) => {
    const tempAvailableSizes = productData?.variants
      ?.filter((variant) => variant?.originalPrice !== undefined)
      .map((variant) => variant?.attributes?.size);
    setAvailableSizes([]?.concat(...tempAvailableSizes));

    const matchingVariant = productData.variants.find(
      (variant) =>
        variant?.attributes?.actualColor === color &&
        variant?.attributes?.actualSize === selectedVariant?.attributes?.actualSize,
    );
    if (matchingVariant) {
      changeVariant(matchingVariant);
    }
  };

  let productVariants: Variant[] = [];
  const colorKeys = [];
  const pushedColors = [];
  const dynamicWidth = calculateDynamicImageWidth(40, 40, 40);
  colors.map((color) => colorKeys.push(color?.key));
  if (colors?.length != productData?.variants?.length && colors?.length > 0) {
    productData?.variants?.some((item) => {
      if (
        !pushedColors?.includes(item?.attributes?.actualColor) &&
        colorKeys?.includes(item?.attributes?.actualColor)
      ) {
        productVariants.push(item);
        pushedColors.push(item?.attributes?.actualColor);
      }
      return pushedColors?.length === colors?.length;
    });
  } else {
    productVariants = productData?.variants;
  }

  const handleVariant = (e, variant) => {
    const productSlug = productData?.slug;
    if (productSlug) {
      const newLink = buildLinkProductVariant(router.basePath, productSlug, variant?.attributes?.actualColor);
      setLink(newLink);
    }
  };

  const getCurrentNavFromURL = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const previousURL = window.location.href.split('/');
    return queryParams.get('currentNav') || previousURL[previousURL.length - 1];
  };

  return (
    <>
      {colors?.length > 0 && (
        <ul className="my-4 flex flex-wrap justify-start gap-4 py-[6px] md:justify-center md:px-[6px]">
          {productVariants?.map((variant, index) => {
            const isDisabled = !productData.variants.some(
              (current) =>
                current?.attributes?.actualColor === variant?.attributes?.actualColor &&
                current?.attributes?.actualSize === selectedVariant?.attributes?.actualSize,
            );
            const isClearanceVariant = variant?.originalPrice;

            return (
              <li key={index} title={variant?.attributes?.actualColor || ''}>
                <Link href={!isClearanceVariant && !isClearanceCategory ? link : ''} passHref>
                  <a
                    id={variant?.attributes?.actualColor?.trim()?.split(' ')?.join('')}
                    className={`flex shrink-0 items-center justify-center rounded-full border-2 p-[2px] ${
                      selectedVariant?.attributes?.actualColor === variant?.attributes?.actualColor
                        ? 'rounded-full border-2 border-black-100 p-[2px]'
                        : ''
                    }`}
                    onClick={(e) => {
                      if (!isClearanceVariant && isClearanceCategory) {
                        e.preventDefault();
                        handleVariant(e, variant);
                        // setShowClearanceVariantWarning(true);
                        onColorPick(variant?.attributes?.actualColor);
                      } else if (e.button === EVENT_LEFT_CLICK) {
                        //Left click (0 will be the value)
                        setShowClearanceVariantWarning(false);
                        e.preventDefault();
                        onColorPick(variant?.attributes?.actualColor);
                      }
                    }}
                  >
                    <Image
                      key={index}
                      loader={({ src }) => src}
                      width={32}
                      height={32}
                      src={
                        format(AMPLIENCE_IMAGE_URL_FORMAT, [
                          omitFileExtension(variant?.images[variant?.images?.length - 1].url) || NoImageAvailable?.src,
                          dynamicWidth,
                        ]) || '#'
                      }
                      alt={variant?.attributes?.actualColor}
                      className={`flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center
              rounded-[50%] ${isDisabled ? 'cursor-not-allowed' : ''} ${
                        !availableColors?.includes(variant?.attributes?.actualColor) && isClearanceCategory
                          ? ' opacity-30'
                          : ''
                      }`}
                    />
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

const ImageGalleryCarousel = ({
  productData,
  selectedVariant,
  setSelectedVariant,
  isQuickView,
  optionalAccessory,
  setSelectedCarouselImage,
  closePlpQuickViewModal,
  pdpNavigationLink,
  isClearanceCategory,
}: ImageGalleryCarouselProps) => {
  const [quantity, setQuantity] = useState(1);
  const [showOptionalAccessories, setShowOptionalAccessories] = useState(false);
  const [isAccessoriesOnly, setIsAccessoriesOnly] = useState(null);
  const [accessoryCategories, setAccessoryCategories] = useState([]);
  const [accessoryCategoryGrouping, setAccessoryCategoryGrouping] = useState(null);
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const availableQuantity = selectedVariant?.isDiscontinued
    ? selectedVariant?.availableQuantity
    : productData?.maxQuantityPerVariant;

  //next/router
  const router = useRouter();
  const { account } = useAccount();
  const { addItem, addMultipleItems, data: cart } = useCart();

  const { getAvailabilityMessage, getBulbORRugModelProducts } = useProduct();
  const [sliceNumber, setSliceNumber] = useState<number>(4);
  const [selectedImage, setSelectedImage] = useState(null) as any;
  const [imageGallery, setImageGallery] = useState(null) as any;
  const [availability, setAvailability] = useState<string>('');
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [showClearanceVariantWarning, setShowClearanceVariantWarning] = useState(
    isClearanceCategory && selectedVariant?.originalPrice == undefined,
  );
  const [link, setLink] = useState('');

  //i18n messages
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const [isDesktopSize] = useMediaQuery(desktop);

  //create delete modal
  const [quickViewVideoModalOpen, setQuickViewVideoModalOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [altText, setAltText] = useState('None');
  const [imagesrc, setImagesrc] = useState();
  const [giftRegistryOpen, setGiftRegistryOpen] = useState(false);
  const [giftRegistryModalOpen, setGiftRegistryModalOpen] = useState(false);
  const [modelProducts, setModelProducts] = useState<any>([]);
  const [isBulbModel, setIsBulbModel] = useState(true);
  const [isRugModel, setIsRugModel] = useState(true);
  const [loading, setLoading] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const { getItem } = useLocalStorage();

  const [isPurchaseAccessoriesOnly, setIsPurchaseAccessoriesOnly] = useState(false);
  const openQuickViewModal = (e) => {
    e.preventDefault();
    setQuickViewVideoModalOpen(true);
  };
  const openGiftRegistryModal = (e) => {
    e.preventDefault();
    setGiftRegistryModalOpen(true);
  };
  const closeGiftRegistryModal = () => {
    setGiftRegistryModalOpen(false);
  };

  const openImageModal = (e) => {
    e.preventDefault();
    if (isDesktopSize) {
      setImageModalOpen(true);
    }
  };

  const StopPropagation = (e) => {
    e.preventDefault();
  };

  const closeQuickVideoViewModal = () => {
    setQuickViewVideoModalOpen(false);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };
  const { showNotification } = useNotification();

  //create bulb slector modal
  const [bulbSelectorModalOpen, setBulbSelectorModalOpen] = useState(false);
  const openBulbSelectorModal = (e) => {
    e.preventDefault();
    setBulbSelectorModalOpen(true);
  };

  const closeBulbSelectorModal = () => {
    setBulbSelectorModalOpen(false);
  };
  const addBulbSelectorModal = () => {
    setBulbSelectorModalOpen(false);
    if (typeof closePlpQuickViewModal === 'function') closePlpQuickViewModal();
  };

  useEffect(() => {
    if (isClearanceCategory) {
      productData?.variants?.map((variant) => {
        if (variant?.originalPrice) {
          setSelectedVariant(variant);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isClearanceCategory && !selectedVariant?.originalPrice) {
      setShowClearanceVariantWarning(true);
    } else {
      setShowClearanceVariantWarning(false);
    }
  }, [router.asPath, isClearanceCategory, selectedVariant]);

  useEffect(() => {
    gaViewItem(productData?.name, selectedVariant, account?.email);
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedVariant) {
      const imageGallery = selectedVariant?.images
        .filter((item) => item.label != 'Swatch')
        .map((item) => {
          return {
            src: item.url,
            alt: productData.name,
          };
        });
      imageGallery.length === 0
        ? setImageGallery([{ src: undefined, alt: productData.name }])
        : setImageGallery(imageGallery);
    } else {
      if (isClearanceCategory) {
        productData?.variants?.map((variant) => {
          if (variant?.originalPrice) {
            setSelectedVariant(variant);
          }
        });
      } else {
        setSelectedVariant(productData?.variants?.[0]);
      }
    }
    setQuantity(1);

    if (selectedVariant?.sku) {
      getAvailabilityMessage(selectedVariant?.sku).then((inventory) => {
        if (selectedVariant?.isAvailable != undefined) {
          setIsAvailable(selectedVariant?.isAvailable);
          setAvailability(inventory?.availabilityMessage);
        }
      });
    } else {
      if (selectedVariant?.isAvailable != undefined) {
        setIsAvailable(selectedVariant?.isAvailable);
        setAvailability(mapLocaleToMeaningfulFormat(router.locale).notAvailable);
      }
    }

    getBulbORRugModelProducts({
      ...selectedVariant?.attributes,
      productCategories: productData?.categories,
      productType: productData?.productType,
      productSlug: productData?.slug,
    }).then((data: any) => {
      if (!data?.products || data?.products?.length === 0) {
        setBulbSelectorModalOpen(false);
      }

      if (data?.products) {
        setModelProducts(data);
      } else {
        setBulbSelectorModalOpen(false);
      }
    });
  }, [selectedVariant]);
  useEffect(() => {
    localStorage.setItem('pdpRoutePath', router.asPath);
  }, []);
  useEffect(() => {
    if (imageGallery) {
      setSelectedImage(imageGallery[0]);
    }
  }, [imageGallery]);

  useEffect(() => {
    setSelectedCarouselImage(selectedImage);
  }, [selectedImage]);

  const handleQuantity = (e) => {
    if (e != undefined) {
      const numberRegexPattern = /^[0-9\b]+$/;
      if (e.target.value === '' || numberRegexPattern.test(e.target.value)) {
        const count = parseInt(e.target.value);
        if (!isNaN(count)) {
          if (count >= 1 && count <= availableQuantity) {
            setQuantity(count);
          } else if (count === 0) {
            setQuantity(1);
          }
        } else {
          setQuantity(e.target.value);
        }
      }
    }
  };

  const versionVideo = new Date().getTime();
  const videoURL = selectedVariant?.attributes?.productVideo
    ? `${selectedVariant?.attributes?.productVideo}?version=${versionVideo}`
    : '';

  const changeVariant = (variant: Variant) => {
    setSelectedVariant(variant);
  };
  useEffect(() => {
    if (selectedVariant && !isQuickView && selectedVariant?.attributes?.actualColor) {
      router.replace(
        `${routerURL?.products}/${productData?.slug}?color=${selectedVariant?.attributes?.actualColor}${
          selectedVariant?.attributes?.size ? '&size=' + selectedVariant?.attributes?.size : ''
        }`,
        undefined,
        {
          shallow: true,
        },
      );
    }
  }, [selectedVariant]);
  const getDistinct = (variants, fieldName) => {
    const colorKeys = variants?.map((variant) => variant.attributes[fieldName]);
    const isSameColor = colorKeys?.every((val, i, arr) => val === arr[0]);

    return isSameColor ? [] : Array.from(new Set(colorKeys)).map((color) => ({ key: color, label: color }));
  };

  const { loggedIn } = useAccount();
  const colorSelection: any = getDistinct(productData?.variants, 'actualColor');
  const sizeSelection: any = getDistinct(productData?.variants, 'size');
  const finishSelection: any = getDistinct(productData?.variants, 'actualFinish');
  const dynamicWidth = calculateDynamicImageWidth(675, 675, 675);
  const handleAddToCart = async (e): Promise<void> => {
    const algoliaQueryID = getAlgoliaQueryID();
    const productPrice = (selectedVariant?.discountedPrice?.centAmount || selectedVariant?.price?.centAmount) / 100;
    const discount =
      selectedVariant?.discountedPrice?.centAmount && selectedVariant?.originalPrice?.centAmount
        ? (selectedVariant?.originalPrice?.centAmount - selectedVariant?.discountedPrice?.centAmount) / 100
        : null;

    algoliaQueryID
      ? addedToCartObjectIDsAfterSearch({
          index: getItem('currentIndex'),
          eventName: 'Product Added to Cart After Search',
          queryID: algoliaQueryID,
          objectIDs: [selectedVariant.sku],
          objectData: [
            {
              price: productPrice,
              quantity,
              ...(discount ? { discount } : {}),
            },
          ],
          value: productPrice * quantity,
          currency: selectedVariant?.price?.currencyCode,
        })
      : addedToCartObjectIDs({
          index: getItem('currentIndex'),
          eventName: 'Product Added to Cart',
          objectIDs: [selectedVariant.sku],
          objectData: [
            {
              price: productPrice,
              quantity,
              ...(discount ? { discount } : {}),
            },
          ],
          value: productPrice * quantity,
          currency: selectedVariant?.price?.currencyCode,
        });

    setAlgoliaQueryIDForPurchase([
      ...getAlgoliaQueryIDForPurchase(),
      {
        queryID: algoliaQueryID,
        sku: selectedVariant.sku,
      },
    ]);

    e.preventDefault();
    setLoading(true);
    try {
      // Add the main item
      if (!isPurchaseAccessoriesOnly) {
        const updatedCart = await addItem(selectedVariant, quantity);
        gaAddToCart(productData, selectedVariant, quantity);
        listrakSCAEvent(updatedCart, account);
      }
      // Add accessories one by one
      if (selectedAccessories && selectedAccessories.length) {
        await addMultipleItems(selectedAccessories);
      }
    } catch (error) {
      // Handle any errors that occurred
    }
    setLoading(false);
    showNotification(LINEITEM_ADDED, 'success');

    if (modelProducts?.products?.length > 0) {
      const type = modelProducts?.requestType;

      if (type === 'bulb-selector') {
        setBulbSelectorModalOpen(true);
        setIsBulbModel(true);
        setIsRugModel(false);
      } else if (type === 'rug-selector') {
        setBulbSelectorModalOpen(true);
        setIsBulbModel(false);
        setIsRugModel(true);
      } else {
        setIsBulbModel(false);
        setIsRugModel(false);
      }
    } else {
      if (!bulbSelectorModalOpen && isDesktopSize && !isQuickView) {
        triggerMiniCart();
      }
      if (typeof closePlpQuickViewModal === 'function') closePlpQuickViewModal();
    }
    return null;
  };
  const onAddToWishlist = async () => {
    if (loggedIn) {
      try {
        closeImageModal();
        if (!quickViewVideoModalOpen) {
          await addToWishlist(selectedVariant.sku, quantity);
          showNotification(LINEITEM_ADDED, 'success');
        }
      } catch {
        showNotification(LINEITEM_ADDED_UNSUCCESSFUL, 'error');
      }
    } else {
      const wishListObject = { sku: selectedVariant?.sku, quantity: quantity };
      localStorage.setItem(WISHLIST_DATA, JSON?.stringify(wishListObject));
      router.push({
        pathname: routerURL.login,
        query: { redirectURL: router.asPath },
      });
    }
  };

  const increaseQuantity = () => {
    if (quantity === 0) {
      setQuantity(1);
    } else {
      if (quantity < availableQuantity) {
        setQuantity(quantity + 1);
      }
    }
  };

  const decreaseQuantity = () => {
    if (quantity <= 1) {
      setQuantity(1);
    } else {
      setQuantity(quantity - 1);
    }
  };

  const [subMenuArrowDown, setSubMenuArrowDown] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectedAccessory, setSelectedAccessory] = useState('');
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((current) => !current);
  const handleCategoryChange = () => {
    setSelectedAccessory('');
  };

  const handleAccessoryChange = (sku, variant, e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setOptionalAccessoryArray(sku, variant);
    }
  };
  const toggleDropdown = () => {
    // setIsDropdownOpen(!isDropdownOpen);
    setSubMenuArrowDown(!subMenuArrowDown);
  };
  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  function groupProductsByCategory(accessoryItem) {
    const groupedProducts = {};
    if (!accessoryItem || accessoryItem.length == 0) {
      setShowOptionalAccessories(false);
      return groupedProducts;
    }
    setShowOptionalAccessories(true);

    accessoryItem.forEach((product) => {
      const accessoryCategory = product?.obj?.masterData?.current?.masterVariant?.attributes.find(
        (attribute) => attribute.name === 'accessoryCategory',
      );

      if (accessoryCategory) {
        const categoryKey = accessoryCategory.value.key;

        if (!groupedProducts[categoryKey]) {
          groupedProducts[categoryKey] = [];
        }
        groupedProducts[categoryKey].push(product);
      }
    });

    return groupedProducts;
  }

  useEffect(() => {
    const accessoryCategoryGrouping = groupProductsByCategory(selectedVariant?.attributes?.accessoryItem);
    const accessoryCategories = Object.keys(accessoryCategoryGrouping);
    setAccessoryCategories(accessoryCategories);
    setAccessoryCategoryGrouping(accessoryCategoryGrouping);
  }, [selectedVariant]);

  const getPoster = (url: string) => {
    const posterUrl = url.substring(0, url.lastIndexOf('/'));
    return posterUrl;
  };

  const checkedOptionAccessories = (sku) => {
    selectedAccessories.length > 0 ? setIsAccessoriesOnly(true) : setIsAccessoriesOnly(false);
    return selectedAccessories.some((accessory) => accessory?.variant?.sku === sku);
  };
  useEffect(() => {
    setSelectedAccessories([]);
    setIsPurchaseAccessoriesOnly(false);
  }, [accessoryCategoryGrouping]);
  const setOptionalAccessoryArray = (sku, variant) => {
    const _selectedOptionalAccessory = JSON.parse(JSON.stringify(selectedAccessories));
    if (_selectedOptionalAccessory.some((accessory) => accessory?.variant?.sku === sku)) {
      const itemIndex = _selectedOptionalAccessory.findIndex((accessory) => accessory?.variant?.sku === sku);
      if (itemIndex !== -1) {
        _selectedOptionalAccessory.splice(itemIndex, 1);
      }
    } else {
      _selectedOptionalAccessory.push({ variant: variant, quantity: 1 });
    }
    setSelectedAccessories(_selectedOptionalAccessory);
  };
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };
  const handleUpdateQuantity = () => {
    if (!quantity) {
      setQuantity(1);
    }
  };

  const handleTouchEnd = (e) => {
    setTouchEndX(e.changedTouches[0].clientX);
    handleSwipe(touchStartX, e.changedTouches[0].clientX, imageGallery, selectedImage, setSelectedImage);
  };
  const colorSizeclassNames = 'mb-3 text-left text-sm leading-[17px] text-sol-300 md:text-center';
  // const accessoryCategoryValue = productData?.variants[0]?.attributes?.accessoryCategory;
  const [currentIndex, setCurrentIndex] = useState(null);
  accessoryCategories && accessoryCategories.sort();

  useEffect(() => {
    const tempAvailableColors = productData?.variants
      ?.filter((variant) => variant?.originalPrice !== undefined)
      ?.map((variant) => variant?.attributes?.actualColor);
    setAvailableColors([]?.concat(...tempAvailableColors));
  }, [productData]);
  return (
    <>
      <script>
        MyRegistrySDK.Behaviors.on(`${'gift_option_bound'}`, function(e){});
      </script>
      <section className="grid gap-10 md:flex md:justify-between md:gap-8">
        <section className="w-full">
          <section className="grid w-full gap-4 lg:flex lg:gap-8">
            <section className="h-max w-full md:max-w-[672px]">
              <button
                type="button"
                id={selectedImage?.alt}
                className="flex h-max w-full cursor-pointer"
                onClick={(e) => {
                  setImagesrc(selectedImage?.src);
                  setAltText(selectedImage?.alt);
                  openImageModal(e);
                }}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
              >
                <Image
                  loader={({ src }) => src}
                  width={672}
                  height={672}
                  src={
                    format(AMPLIENCE_IMAGE_URL_FORMAT, [
                      omitFileExtension(selectedImage?.src) || NoImageAvailable?.src,
                      dynamicWidth,
                    ]) || '#'
                  }
                  alt={selectedImage?.alt}
                  className="w-full rounded-[6px] object-center"
                />
              </button>
            </section>
            <section className="flex flex-col justify-between gap-5">
              {imageGallery ? (
                <VerticalCarousel
                  itemList={imageGallery}
                  setNumber={setSliceNumber}
                  number={sliceNumber}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              ) : (
                <></>
              )}
              {selectedVariant?.attributes?.productVideo ? (
                <section className="relative inline-block opacity-50">
                  <button type="button" onClick={openQuickViewModal} className="relative flex">
                    <Image
                      loader={({ src }) => src}
                      width={91}
                      height={91}
                      // src={getPoster(selectedVariant?.attributes?.productVideo) ?? ''}
                      src={selectedVariant?.images[0]?.url}
                      alt="Poster Image"
                      className="rounded-[6px]"
                    />
                    <Icon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </button>
                </section>
              ) : (
                <></>
              )}
            </section>
          </section>
        </section>
        <section className="mx-auto w-full md:max-w-[515px]">
          <section className="mx-auto w-full md:max-w-[400px]">
            <h1 className="mb-5 text-left text-[32px] font-semibold leading-10 text-sol-300 md:text-center">
              {/* TODO Comment : Data will come from BE. We can integrate after BE work */}
              {productData?.name}
            </h1>
            <p className="mb-5 text-left text-xs font-bold leading-[15px] text-sand-100 md:text-center">
              {mapLocaleToMeaningfulFormat(router.locale).sku} {selectedVariant?.sku}
            </p>
            {account && !account?.isB2BApproved && selectedVariant?.originalPrice ? (
              <>
                <p className="text-black inline-block w-full text-center text-sm font-bold leading-[17px]">
                  {' '}
                  {mapLocaleToMeaningfulFormat(router.locale).yourPrice}
                </p>
                <div className="flex gap-2 md:justify-center">
                  {
                    <strong className="block text-left text-base font-semibold leading-5 text-red-500 ">
                      {selectedVariant?.discountedPrice
                        ? CurrencyHelpers.formatForCurrency(selectedVariant?.discountedPrice)
                        : CurrencyHelpers.formatForCurrency(selectedVariant?.price)}
                    </strong>
                  }
                  <strong
                    className={`${
                      selectedVariant?.discountedPrice && 'line-through'
                    } block text-left text-base font-semibold leading-5 text-sol-300 line-through`}
                  >
                    {selectedVariant?.originalPrice
                      ? CurrencyHelpers.formatForCurrency(selectedVariant?.originalPrice)
                      : CurrencyHelpers.formatForCurrency(selectedVariant?.price)}
                  </strong>
                </div>
              </>
            ) : account && account?.isB2BApproved && selectedVariant?.originalPrice ? (
              <>
                <p className="text-black inline-block w-full text-center text-sm font-bold leading-[17px]">
                  {' '}
                  {mapLocaleToMeaningfulFormat(router.locale).yourPrice}
                </p>
                <div className="flex gap-2 md:justify-center">
                  {selectedVariant?.price && (
                    <strong className="block text-left text-base font-semibold leading-5 text-red-500 ">
                      {CurrencyHelpers.formatForCurrency(selectedVariant?.price)}
                    </strong>
                  )}
                  <strong
                    className={`${
                      selectedVariant?.originalPrice && 'line-through'
                    } block text-left text-base font-semibold leading-5 text-sol-300`}
                  >
                    {CurrencyHelpers.formatForCurrency(selectedVariant?.originalPrice)}
                  </strong>
                </div>
              </>
            ) : (
              <div className="flex gap-2 md:justify-center">
                {selectedVariant?.originalPrice ? (
                  <>
                    <strong className="block text-left text-base font-semibold leading-5 text-red-500">
                      {CurrencyHelpers.formatForCurrency(selectedVariant?.price)}
                    </strong>
                    <strong className="block text-left text-base font-semibold leading-5 text-sol-300 line-through">
                      {CurrencyHelpers.formatForCurrency(selectedVariant?.originalPrice)}
                    </strong>
                  </>
                ) : selectedVariant?.discountedPrice ? (
                  <>
                    <strong className="block text-left text-base font-semibold leading-5 text-red-500">
                      {CurrencyHelpers.formatForCurrency(selectedVariant?.discountedPrice)}
                    </strong>
                    <strong className="block text-left text-base font-semibold leading-5 text-sol-300 line-through">
                      {CurrencyHelpers.formatForCurrency(selectedVariant?.price)}
                    </strong>
                  </>
                ) : (
                  <strong className="block text-left text-base font-semibold leading-5 text-sol-300">
                    {CurrencyHelpers.formatForCurrency(selectedVariant?.price)}
                  </strong>
                )}
              </div>
            )}
            <hr className="my-8 h-px border-t border-[#B19A6A]" />
            {selectedVariant?.attributes?.actualColor && (
              <p className={colorSizeclassNames}>
                <strong className="mr-2 font-semibold">{mapLocaleToMeaningfulFormat(router.locale).color}:</strong>
                <span>{selectedVariant?.attributes?.actualColor}</span>
              </p>
            )}
            <section className="flex justify-start md:justify-center">
              {productData && colorSelection && (
                <ColorSelector
                  productData={productData}
                  colors={colorSelection}
                  selectedVariant={selectedVariant}
                  changeVariant={changeVariant}
                  isClearanceCategory={isClearanceCategory}
                  setShowClearanceVariantWarning={setShowClearanceVariantWarning}
                  link={link}
                  setLink={setLink}
                  availableColors={availableColors}
                  setAvailableSizes={setAvailableSizes}
                />
              )}
              {/* size section */}
            </section>
            <section className="text-center">
              <SizeSelector
                productData={productData}
                sizes={sizeSelection}
                selectedVariant={selectedVariant}
                changeVariant={changeVariant}
                availableSizes={availableSizes}
                setAvailableColors={setAvailableColors}
                isClearanceCategory={isClearanceCategory}
              />
            </section>
            <section className="text-center">
              <FinishSelector
                productData={productData}
                finishes={finishSelection}
                selectedVariant={selectedVariant}
                changeVariant={changeVariant}
              />
            </section>
            {/* {
              selectedVariant?.attributes?.actualSize > 0 && ( */}
            {selectedVariant?.attributes?.actualSize && (
              <p className={colorSizeclassNames}>
                <strong className="mr-2 font-semibold">{mapLocaleToMeaningfulFormat(router.locale).actualSize}</strong>
                <span>{selectedVariant?.attributes?.actualSize}</span>
              </p>
            )}
            {availability && (
              <section className="pt-6">
                <section
                  className={`flex items-center text-left text-sm font-semibold leading-[17px] ${
                    isAvailable ? 'text-sol-300' : 'text-red-500'
                  }`}
                >
                  <section className="mr-2 h-1 w-1 rounded-[50%] bg-black-300"></section>
                  <span>{availability}</span>
                </section>
              </section>
            )}
            {productData?.discountCodes?.length > 0 && (
              <ul className="grid gap-2 pt-6">
                {productData?.discountCodes?.map((discount: any, key: any) => {
                  return (
                    <>
                      {discount?.isPDPDisplayEnabled ? (
                        <li key={key} className="flex leading-5 ">
                          {discount?.pdpDiscountTxt ? (
                            <>
                              <section className="mr-2 mt-2 h-1 w-1 shrink-0 rounded-[50%] bg-black-300"></section>
                              {!selectedVariant?.originalPrice ? (
                                <p className={`text-left text-sm font-bold `}>{discount?.pdpDiscountTxt}</p>
                              ) : (
                                <p className={`text-left text-sm font-bold `}>
                                  {mapLocaleToMeaningfulFormat(router.locale).Additional} {discount?.pdpDiscountTxt}
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <section className="mr-2 mt-2 h-1 w-1 shrink-0 rounded-[50%] bg-black-300"></section>
                              <p className={`text-left text-sm font-bold `}>
                                {StringHelpers.formatDiscountText(discount, router)}
                              </p>
                            </>
                          )}
                        </li>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </ul>
            )}
            {!validateCartItemQty(cart, selectedVariant, availableQuantity, quantity) && (
              <section className="pt-6">
                <section className={`flex items-center text-left text-sm font-semibold leading-[17px] text-red-500`}>
                  <section className="mr-2 h-1 w-1 rounded-[50%] bg-red-300"></section>
                  <span>{format(CART_ITEM_MAX_QTY_REACHED, [availableQuantity])}</span>
                </section>
              </section>
            )}
            {showClearanceVariantWarning && (
              <section className="pt-6">
                <section className={`flex items-center text-left text-sm font-semibold leading-[17px] text-red-500`}>
                  <section className="mr-2 h-1 w-1 rounded-[50%] bg-red-300"></section>
                  <p className="flex items-center gap-1">
                    <span className="whitespace-nowrap">
                      {modelProducts?.requestType === 'rug-selector'
                        ? mapLocaleToMeaningfulFormat(router.locale).clearanceVariantRugMessage
                        : mapLocaleToMeaningfulFormat(router.locale).clearanceVariantMessage}
                    </span>
                    <span
                      onClick={() => {
                        localStorage.setItem(PREVIOUS_PATH, '/');
                        router.push(link);
                      }}
                      className="cursor-pointer font-extrabold underline"
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).here}
                    </span>
                  </p>
                </section>
              </section>
            )}
            {showOptionalAccessories ? (
              <>
                <section className="mt-8">
                  <p className="mb-3 text-center text-base font-normal leading-5 text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).optionalAccessories}
                  </p>
                  <ul className="divide-y divide-gray-200 bg-white">
                    {accessoryCategories?.map((category, index) => (
                      <li key={index}>
                        <Accordion
                          handleAccessoryChange={handleAccessoryChange}
                          downrodsIncluded={selectedVariant?.attributes?.downrodsIncluded}
                          setCurrentIndex={setCurrentIndex}
                          currentIndex={currentIndex}
                          index={index}
                          selectedVarient={selectedVariant}
                          handleCategoryChange={handleCategoryChange}
                          title={category}
                          accessoryCategoryGrouping={accessoryCategoryGrouping}
                          checkedOptionAccessories={checkedOptionAccessories}
                          optionalAccessory={optionalAccessory}
                          selectedAccessories={selectedAccessories}
                          setSelectedAccessories={setSelectedAccessories}
                        />
                      </li>
                    ))}
                  </ul>
                  {isAccessoriesOnly && (
                    <div className="mb-5 flex h-12 items-center rounded-md border bg-white py-2  px-3 transition-transform">
                      <label className="flex cursor-pointer items-center gap-2" htmlFor="accessories_only">
                        <section className="relative">
                          <input
                            className="filterNavCheckbox h-4 w-4 cursor-pointer appearance-none rounded-[2px] border !border-black-900 text-white shadow-none focus:ring-0"
                            type="checkbox"
                            name="accessories_only"
                            checked={isPurchaseAccessoriesOnly}
                            id="accessories_only"
                            onClick={() => setIsPurchaseAccessoriesOnly(!isPurchaseAccessoriesOnly)}
                            onKeyDown={(e) => {
                              e.key === 'Enter' && setIsPurchaseAccessoriesOnly(!isPurchaseAccessoriesOnly);
                            }}
                            value="true"
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="7"
                            viewBox="0 0 8 7"
                            fill="none"
                            className={`checked-icon absolute top-[9px] left-1/2 hidden h-2 w-2 -translate-x-1/2`}
                          >
                            <path
                              d="M2.134 5.95163C2.2236 6.04791 2.3302 6.12432 2.44766 6.17647C2.56511 6.22862 2.69108 6.25547 2.81832 6.25547C2.94556 6.25547 3.07154 6.22862 3.18899 6.17647C3.30644 6.12432 3.41304 6.04791 3.50264 5.95163L7.43506 1.7607C7.61655 1.56727 7.71851 1.30493 7.71851 1.03139C7.71851 0.757849 7.61655 0.495511 7.43506 0.302087C7.25356 0.108664 7.00741 0 6.75074 0C6.49407 0 6.24791 0.108664 6.06642 0.302087L2.81832 3.77399L1.65209 2.52082C1.4706 2.32739 1.22444 2.21873 0.967771 2.21873C0.711102 2.21873 0.464946 2.32739 0.283454 2.52082C0.101961 2.71424 0 2.97658 0 3.25012C0 3.52366 0.101961 3.786 0.283454 3.97943L2.134 5.95163Z"
                              fill="#222222"
                            ></path>
                          </svg>
                        </section>
                        <div className="px-2">
                          <p className="text-sm font-normal leading-5 text-sol-300">
                            {mapLocaleToMeaningfulFormat(router.locale).purchaseAccessoriesOnly}
                          </p>
                        </div>
                      </label>
                    </div>
                  )}
                </section>
              </>
            ) : (
              <></>
            )}
            {!showClearanceVariantWarning && (
              <>
                <hr className="my-8 h-px border-t border-[#B19A6A]" />
                <section className="mb-6 flex items-center justify-end gap-[10px]">
                  <label className="text-sm font-semibold text-sol-300" htmlFor="itemQuantity">
                    {mapLocaleToMeaningfulFormat(router.locale).quantity}
                  </label>
                  <section
                    className={`${
                      isDesktopSize ? 'w-[80px]' : 'w-[6.25rem]'
                    } relative flex h-11 items-center justify-between rounded-lg border`}
                  >
                    <section>
                      <input
                        id="itemQuantity"
                        type="number"
                        value={quantity}
                        disabled={!isAvailable}
                        onBlur={handleUpdateQuantity}
                        onChange={handleQuantity}
                        className={`${
                          !isAvailable ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                        } input-number w-[53px] rounded-l-lg border-none text-center focus:border-none focus:outline-none focus:ring-0`}
                        min="1"
                        max={availableQuantity}
                      />
                    </section>
                    <section className="absolute right-2 flex h-[42px] flex-col items-center justify-center">
                      {!isAvailable ? (
                        <>
                          <button
                            type="button"
                            aria-label="increment"
                            id="incrementBtn"
                            disabled
                            className="cursor-not-allowed opacity-50"
                          >
                            <MdOutlineKeyboardArrowUp className="h-5 w-5 cursor-not-allowed pt-1" />
                          </button>
                          <button
                            type="button"
                            aria-label="decrement"
                            className="cursor-not-allowed opacity-50"
                            disabled
                            id="decrementBtn"
                          >
                            <MdOutlineKeyboardArrowDown className="h-5 w-5 cursor-not-allowed pb-1" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button type="button" aria-label="increment" id="incrementBtn" onClick={increaseQuantity}>
                            <MdOutlineKeyboardArrowUp
                              className={`${
                                quantity >= availableQuantity ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                              } h-5 w-5 pt-1`}
                            />
                          </button>
                          <button type="button" aria-label="decrement" id="decrementBtn" onClick={decreaseQuantity}>
                            <MdOutlineKeyboardArrowDown
                              className={`${
                                quantity > 1 ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
                              } h-5 w-5 pb-1`}
                            />
                          </button>
                        </>
                      )}
                    </section>
                  </section>

                  {!isAvailable ||
                  !quantity ||
                  !validateCartItemQty(cart, selectedVariant, availableQuantity, quantity) ||
                  loading ? (
                    <button
                      type="button"
                      className="btn-primary-small !mb-0 h-11 w-full cursor-not-allowed opacity-50 sm:w-[233px]"
                      id="addToCartBtn"
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-primary-small !mb-0 h-11 w-full cursor-pointer sm:w-[233px]"
                      onClick={handleAddToCart}
                      id="addToCartBtn"
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                    </button>
                  )}
                </section>
                <section className="mt-6 flex justify-end">
                  <button
                    onClick={onAddToWishlist}
                    type="button"
                    id="addMyList"
                    className="text-left text-sm font-semibold leading-[17px] text-sol-300"
                  >
                    {mapLocaleToMeaningfulFormat(router.locale).addMyList}
                  </button>
                </section>
                <section className="mt-[18px] flex justify-end">
                  <button
                    type="button"
                    id="addGiftRegistry"
                    className="text-left text-sm font-semibold leading-[17px] text-sol-300"
                    onClick={() =>
                      MyGiftRegistry.addToGiftRegistry(
                        productData,
                        selectedVariant,
                        quantity,
                        isQuickView,
                        pdpNavigationLink,
                      )
                    }
                  >
                    {mapLocaleToMeaningfulFormat(router.locale).addGiftRegistry}
                  </button>
                </section>
              </>
            )}
          </section>
        </section>
      </section>
      <QuickViewModal open={quickViewVideoModalOpen} videoUrl={videoURL} onClose={closeQuickVideoViewModal} />
      <BulbSelectorModal
        selectedVariant={selectedVariant}
        isQuickView={isQuickView}
        modelProducts={modelProducts}
        openModal={bulbSelectorModalOpen}
        productData={productData}
        isRugModel={isRugModel}
        isBulbModel={isBulbModel}
        onClose={closeBulbSelectorModal}
        onAdd={addBulbSelectorModal}
        baseQuantity={quantity}
      />
      <ImageModal open={imageModalOpen} onClose={closeImageModal} src={imagesrc} alt={altText} />
      {/* <GiftRegistryModal
        open={giftRegistryModalOpen}
        onClose={closeGiftRegistryModal}
        selectedVariant={selectedVariant}
        productData={productData}
      /> */}
    </>
  );
};

export default ImageGalleryCarousel;
