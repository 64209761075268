import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Listbox, Transition } from '@headlessui/react';
import { Card } from '@Types/account/AuthorizeNetAccount';
import { Cart } from '@Types/cart/Cart';
import { TransactionRequest } from '@Types/payment';
import { Field, Form } from 'formik';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import BackIcon from 'components/icons/sol/backIcon';
import CreditCardIcon from 'components/icons/sol/credit-card-icon';
import CardIcon from 'components/sol-ui/account/credit-card/card-icon';
import Spinner from 'components/sol-ui/notification/spinner';
import { PAYMENT_TOKEN, CARD_NAME, PAYMENT_PROFILE } from 'helpers/constants/localStorage';
import { CART_PAYMENT_SUCCESS, SERVER_ERROR, TRANSACTION_DECLINED } from 'helpers/constants/messages';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { useNotification } from 'helpers/notification/notificationContext';
import { gaPurchase } from 'helpers/utils/ga4-events/purchase';
import { gaViewCart } from 'helpers/utils/ga4-events/viewCart';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { isCartIsValid } from 'helpers/utils/validateCart';
import { useCart, useAccount, useAuthorizeNet } from 'frontastic';
import OrderReviewSummary from '../../line-Item';
import CheckoutSummary from '../../order-summary';
import AddressSummary from '../address-summary';
import PaymentMethodSection from '../checkout-payment/payment-method-section';
import ProgressIndicator from '../progress-indicator';
function CheckoutReview({ data, content }) {
  const { isGuestUser, removeAsGuest, getItem } = useLocalStorage();
  const { loggedIn, account } = useAccount();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const [activeStep, setActiveStep] = useState(3);
  const { data: cartData, redeemDiscountCode, removeDiscountCode } = useCart();
  const { data: cards, getCard, makePayment } = useAuthorizeNet();
  const [creditCard, setCreditCard] = useState<Card>(null);
  const [cart, setCart] = useState<Cart>(null);
  const isCartValid = isCartIsValid(cart);

  //global notification
  const { showNotification } = useNotification();

  const [filterArrow, setFilterArrow] = useState(false);
  const [AddCardModalOpen, setAddCardModalOpen] = useState(false);
  const [guestCreditCard, setGuestCreditCard] = useState<Card>(
    {
      cardNumber: getItem(CARD_NAME),
      customerPaymentProfileId: undefined,
      expirationDate: undefined,
    } || null,
  );

  useEffect(() => {
    if (!cart || cart?.cartId === cartData?.cartId) {
      setCart(cartData);
    }
  }, [cartData]);

  useEffect(() => {
    // if (!isGuestUser && !loggedIn) {
    //   router.push(routerURL.checkout);
    // }

    if (!isGuestUser && cards?.length > 0) {
      setCreditCard(cards.find((card) => card.customerPaymentProfileId === getItem(PAYMENT_PROFILE)) || null);
    }
  }, [cart, isGuestUser]);

  const openCreditCardModal = () => {
    setAddCardModalOpen(true);
  };

  const closeCreditCardModal = () => {
    setAddCardModalOpen(false);
  };

  const handleShareListArrow = () => {
    setFilterArrow(!filterArrow);
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      const transactionRequest: TransactionRequest = {};
      transactionRequest.customerPaymentProfileId = creditCard?.customerPaymentProfileId ?? undefined;
      transactionRequest.paymentToken = getItem(PAYMENT_TOKEN) ?? undefined;
      transactionRequest.customerBillingAddress = isGuestUser
        ? guestCreditCard?.customerBillingAddress
        : creditCard?.customerBillingAddress;

      const response = await makePayment(transactionRequest);
      if (response?.orderNumber) {
        //showNotification(CART_PAYMENT_SUCCESS, 'success');
        removeAsGuest();
        // gaPurchase(cart, response);
        router.push(`${routerURL.thankYou}?order=${response?.orderNumber}`);
      } else {
        showNotification(TRANSACTION_DECLINED, 'error');
      }
    } catch (error) {
      showNotification(TRANSACTION_DECLINED, 'error');
    }
    setLoading(false);
  };
  const Input = styled(InputMask)``;

  return (
    <>
      <section>
        <>
          <section className="mx-auto my-10 max-w-[1180px] px-5">
            <section className="mb-4 flex items-center justify-center gap-1">
              <BackIcon className="fill-black-500" />
              <Link href={routerURL?.cart}>
                <a
                  className="text-sm font-medium leading-[17px] text-sol-300 underline underline-offset-2"
                  onClick={() => {
                    gaViewCart(cart);
                  }}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).returnToCart}
                </a>
              </Link>
            </section>
            <h1 className="mt-6 text-center text-[1.688rem] font-semibold after:mx-auto after:mt-4 after:block after:h-[0.063rem] after:w-[9.375rem] after:bg-[#B19A6A] after:content-[''] lg:mt-2">
              {mapLocaleToMeaningfulFormat(router.locale).checkout}
            </h1>
            <section className="mt-4">
              <ProgressIndicator activeStep={activeStep} setActiveStep={setActiveStep} />
            </section>
            <section dangerouslySetInnerHTML={{ __html: content }} />
            <section className="mt-6 flex flex-col justify-between gap-5 md:grid-cols-2 lg:mt-10 lg:flex-row lg:gap-10 ">
              <section className="flex w-full flex-col">
                <section>
                  <AddressSummary cart={cart} showEditButton={true} />
                </section>
                {isGuestUser && guestCreditCard && (
                  <>
                    <PaymentMethodSection creditCard={guestCreditCard} showEditButton={true} />
                  </>
                )}
                {creditCard && <PaymentMethodSection creditCard={creditCard} showEditButton={true} />}
                <section className="mt-5">
                  <ul>
                    {cart?.lineItems?.map((lineItem, index) => (
                      <li
                        key={index}
                        className="mb-5 mt-3 w-full rounded border px-5 py-6 pb-5 text-sm font-normal text-sol-300 lg:mt-0 lg:py-2"
                      >
                        {!lineItem?.isAvailable && (
                          <p className="mt-4 text-left font-medium text-red-600 lg:pr-4 lg:pb-8">
                            {' '}
                            {mapLocaleToMeaningfulFormat(router.locale).thisProductNoLongerAvailable}
                          </p>
                        )}
                        <OrderReviewSummary
                          componentIdentifier={`checkout`}
                          lineItem={lineItem}
                          showQuantityInput={false}
                          showAddToCartButton={false}
                          showInventory={true}
                          isFromOrderDetails={false}
                          index={index}
                        />
                      </li>
                    ))}
                  </ul>
                </section>
                <>
                  {isCartValid ? (
                    <section className="flex flex-col items-center gap-0 md:flex-row md:gap-6">
                      <button
                        type="button"
                        id="confirmPlaceOrder"
                        onClick={handlePayment}
                        className={`btn-primary-small flex h-[44px] w-full items-center justify-center px-8 md:w-auto`}
                      >
                        {loading ? (
                          <section className="flex items-center justify-center gap-3">
                            {' '}
                            <Spinner />
                            <p>{mapLocaleToMeaningfulFormat(router.locale).processing}</p>
                          </section>
                        ) : (
                          mapLocaleToMeaningfulFormat(router.locale).confirmAndPlaceOrder
                        )}
                      </button>
                    </section>
                  ) : (
                    <section className="flex flex-col items-center gap-0 md:flex-row md:gap-6">
                      <button
                        type="button"
                        id="confirmPlaceOrder"
                        className={`btn-primary-small flex h-[44px] w-full cursor-not-allowed items-center justify-center px-8 opacity-50 md:w-auto`}
                      >
                        {mapLocaleToMeaningfulFormat(router.locale).confirmAndPlaceOrder}
                      </button>
                    </section>
                  )}
                </>
              </section>
              <section>
                <CheckoutSummary
                  checkoutHeading={mapLocaleToMeaningfulFormat(router.locale).orderSummary}
                  showCalculateEstimatedTax={true}
                  showPromoCode={false}
                  showCheckoutButton={false}
                  showHelpLinks={true}
                  cart={cart}
                  redeemDiscountCode={redeemDiscountCode}
                  removeDiscountCode={removeDiscountCode}
                  showShippingPrice={true}
                  showPromoRemoveButton={false}
                  content={data?.showContent ? data?.content?.dataSource?.content?.cartSummaryContent : null}
                  isCheckout={true}
                  isCartValid={isCartValid}
                  isCheckoutAddress={false}
                />
              </section>
            </section>
          </section>
        </>
      </section>
    </>
  );
}

export default CheckoutReview;
