import { StringHelpers } from 'helpers/stringHelpers';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaAddShippingInfo = (cart: any) => {
  const dataLayer = window?.dataLayer;
  try {
    const addShippingInfoObj = {
      currency: cart?.total?.currencyCode,
      value: cart?.total?.centAmount / 10 ** cart?.total?.fractionDigits,
      coupon: cart?.discountCodes[0]?.code || null,
      shipping_tier: 'Ground',
      items: [
        cart.lineItems.map((lineItem) => {
          return {
            item_id: lineItem.variant.sku,
            item_name: lineItem.name,
            index: lineItem.variant.sku,
            item_variant: lineItem.variant?.attributes?.collection,
            price:
              lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
              lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
            quantity: lineItem.count,
          };
        }),
      ],
    };
    dataLayer.push({ ecommerce: null });
    if (cart?.email) {
      dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: addShippingInfoObj,
        email: StringHelpers?.convertToSHA256Hash(cart?.email),
      });
    } else {
      dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: addShippingInfoObj,
      });
    }
  } catch (error) {
    console.log('ga4 add shipping info event fail');
  }
};
