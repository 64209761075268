import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LineItem } from '@Types/cart/LineItem';
import { Money } from '@Types/product/Money';
import { LineItem as WishlistLineItem } from '@Types/wishlist/LineItem';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import {
  LINEITEM_REMOVED,
  LINEITEM_UPDATED,
  LINEITEM_ADDED,
  CART_ITEM_MAX_QTY_REACHED,
} from 'helpers/constants/messages';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { listrakSCAEvent } from 'helpers/listrakHelper';
import { useNotification } from 'helpers/notification/notificationContext';
import { scrollToTop } from 'helpers/scrollToTop';
import { totalPriceCalculation } from 'helpers/totalPriceCalculation';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import { gaAddToCart } from 'helpers/utils/ga4-events/addToCart';
import { gaRemoveFromCart } from 'helpers/utils/ga4-events/removeFromCart';
import { gaSelectItem } from 'helpers/utils/ga4-events/selectItem';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import { desktop } from 'helpers/utils/screensizes';
import { triggerMiniCart } from 'helpers/utils/triggerMiniCart';
import { isAccessoryLinkDisabled, validateWishlistItemQty } from 'helpers/utils/validateCart';
import NoImageAvailable from 'public/images/No-Image-Available.jpg';
import { useWishlist, useCart, useAccount, useConfig } from 'frontastic';
import Image from 'frontastic/lib/image';
import RemoveProduct from '../account/modal/delete-modal';

interface ListItemsProps {
  componentIdentifier?: string;
  lineItem: LineItem | WishlistLineItem;
  showQuantityInput?: boolean;
  showAddToCartButton?: boolean;
  showRemoveButton?: boolean;
  showInventory?: boolean;
  updateLineItem?: (lineItemId: string, count: number) => Promise<void>;
  removeLineItem?: (lineItemId: string) => Promise<void>;
  lineItemQuantityDisabled?: boolean;
  setLineItemQuantityDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  wishlist?: any;
  isFromOrderDetails: boolean;
  index: number;
}

function LineItems({
  componentIdentifier,
  lineItem,
  showQuantityInput,
  showAddToCartButton,
  showRemoveButton,
  showInventory,
  removeLineItem,
  updateLineItem,
  lineItemQuantityDisabled,
  setLineItemQuantityDisabled,
  wishlist,
  isFromOrderDetails,
  index,
}: ListItemsProps) {
  const router = useRouter();
  const { addItem, data: cart } = useCart();
  const { showNotification } = useNotification();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDesktop] = useMediaQuery(desktop);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(lineItem?.count || 0);
  const [availabilityMessage, setAvailabilityMessage] = useState(
    lineItem?.availabilityMessage && lineItem?.availabilityMessage,
  );
  const { account } = useAccount();
  const { data: config } = useConfig();
  const dynamicWidth = calculateDynamicImageWidth(150, 150, 150);

  const maxQuantityPerVariant = lineItem?.maxQuantityPerVariant;
  const isAccessoryDisabled = isAccessoryLinkDisabled(lineItem, config?.disabledAccessories);

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };
  useEffect(() => {
    setQuantity(lineItem.count);
  }, [lineItem.count]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    // scrollToTop();
  };
  useEffect(() => {
    if (lineItem?.availabilityMessage) {
      setAvailabilityMessage(lineItem?.availabilityMessage);
    }
  }, [lineItem?.availabilityMessage]);
  const handleRemoveLineItem = async ({ lineItemId }) => {
    if (lineItemId) {
      await removeLineItem(lineItemId);
      gaRemoveFromCart(cart, lineItem);
      showNotification(LINEITEM_REMOVED, 'success');
      closeDeleteModal();
    }
  };

  const handleQuantity = async (e) => {
    const count = parseInt(e.target.value);
    if (!isNaN(count)) {
      if (count >= 1 && count <= maxQuantityPerVariant) {
        setQuantity(count);
      } else if (count === 0) {
        setQuantity(1);
      }
    } else {
      setQuantity(null);
    }
  };
  const incrementCount = async () => {
    const count = lineItem?.count + 1;
    if (count <= maxQuantityPerVariant) {
      setLoading(true);
      await updateLineItem(lineItem?.lineItemId, count);
      setQuantity(count);
      showNotification(LINEITEM_UPDATED, 'success');
      setLoading(false);
      // scrollToTop();
      listrakSCAEvent(cart, account);
    }
  };
  const decrementCount = async () => {
    const count = lineItem?.count - 1;
    if (count > 0) {
      setLoading(true);
      await updateLineItem(lineItem?.lineItemId, count);
      setQuantity(count);
      showNotification(LINEITEM_UPDATED, 'success');
      setLoading(false);
      // scrollToTop();
      listrakSCAEvent(cart, account);
    }
  };
  const addToCart = async () => {
    setLoading(true);
    const updatedCart = await addItem(lineItem?.variant, lineItem?.count);
    gaAddToCart(lineItem, lineItem?.variant, lineItem?.count);
    listrakSCAEvent(updatedCart, account);
    showNotification(LINEITEM_ADDED, 'success');
    triggerMiniCart();
    setLoading(false);
  };
  const handleUpdateQuantity = async () => {
    if (lineItem?.count != quantity) {
      setLineItemQuantityDisabled(true);
      if (!quantity) {
        setQuantity(1);
      }
      await updateLineItem(lineItem?.lineItemId, quantity);
      showNotification(LINEITEM_UPDATED, 'success');
      // scrollToTop();
      setLineItemQuantityDisabled(false);
    }
  };

  const handleItemClick = () => {
    window.location.href = lineItem?._url;
  };
  const lineItemTrim = lineItem?.name?.trim()?.split(' ')?.join('');

  return (
    <>
      <section className="mt-4 grid grid-rows-1 gap-4 lg:grid-cols-2 lg:gap-12">
        <section className="flex">
          <section className="mr-4 w-[120px] shrink-0">
            {!isAccessoryDisabled ? (
              <Link href={lineItem?._url ?? '#'}>
                <a onClick={handleItemClick}>
                  <Image
                    src={format(AMPLIENCE_IMAGE_URL_FORMAT, [
                      omitFileExtension(lineItem?.variant?.images[0]?.url) || NoImageAvailable?.src,
                      dynamicWidth,
                    ])}
                    alt={lineItem?.name}
                    height={100}
                    width={100}
                    className="rounded-md"
                    onClick={() => {
                      gaSelectItem(wishlist, lineItem);
                    }}
                  />
                </a>
              </Link>
            ) : (
              <Image
                src={format(AMPLIENCE_IMAGE_URL_FORMAT, [
                  omitFileExtension(lineItem?.variant?.images[0]?.url) || NoImageAvailable?.src,
                  dynamicWidth,
                ])}
                alt={lineItem?.name}
                height={100}
                width={100}
                className="rounded-md"
                onClick={() => {
                  gaSelectItem(wishlist, lineItem);
                }}
              />
            )}
          </section>
          <section className="ml-2 text-sm font-normal leading-7 text-sol-300">
            {!isAccessoryDisabled ? (
              <Link href={lineItem?._url ?? '#'}>
                <a onClick={handleItemClick}>
                  <h1 className="cursor-pointer text-base font-semibold text-sol-300">{lineItem?.name}</h1>
                </a>
              </Link>
            ) : (
              <h1 className="text-base font-semibold text-sol-300">{lineItem?.name}</h1>
            )}

            <p>{lineItem?.variant?.sku}</p>
            {lineItem?.variant?.attributes?.actualColor && (
              <p>
                {mapLocaleToMeaningfulFormat(router.locale).color}
                {mapLocaleToMeaningfulFormat(router.locale).colon} {lineItem?.variant?.attributes?.actualColor}
              </p>
            )}
            {lineItem?.variant?.attributes?.filterFinish && (
              <span>
                {mapLocaleToMeaningfulFormat(router.locale).finish}
                {mapLocaleToMeaningfulFormat(router.locale).colon}{' '}
              </span>
            )}
            {lineItem?.variant?.attributes?.filterFinish &&
              lineItem?.variant?.attributes?.filterFinish.map((finish, index) => (
                <span key={finish?.label}>
                  {index > 0 && ', '}
                  <span>{finish?.label}</span>
                </span>
              ))}
            {lineItem?.variant?.attributes?.actualSize &&
              lineItem?.variant?.attributes?.categories &&
              lineItem?.variant?.attributes?.categories[0] === 'Rugs' && ( //Size is only for Rugs
                <p className="capitalize">
                  {mapLocaleToMeaningfulFormat(router.locale).size}
                  {mapLocaleToMeaningfulFormat(router.locale).colon} {lineItem?.variant?.attributes?.actualSize}
                </p>
              )}
            {componentIdentifier === `thankyouPage` || componentIdentifier === `orderDetail` ? null : (
              <>
                {lineItem?.isAvailable !== undefined && lineItem?.isAvailable && showInventory ? (
                  <p className="font-semibold">{availabilityMessage || ''}</p>
                ) : (
                  <p className="text-sm font-semibold text-sol-700">
                    {mapLocaleToMeaningfulFormat(router.locale).notAvailable}
                  </p>
                )}
              </>
            )}
            {componentIdentifier === `thankyouPage` || componentIdentifier === `orderDetail` ? null : (
              <>
                {!validateWishlistItemQty(lineItem, cart, componentIdentifier) && lineItem?.maxQuantityPerVariant && (
                  <p className="font-semibold text-red-500">
                    {format(CART_ITEM_MAX_QTY_REACHED, [lineItem?.maxQuantityPerVariant]) || ''}
                  </p>
                )}
              </>
            )}
            {showRemoveButton && (
              <button type="button" onClick={openDeleteModal} className="mt-2 mb-3 underline underline-offset-2">
                {componentIdentifier === `myList` && `${mapLocaleToMeaningfulFormat(router.locale).removeFromList}`}
                {(componentIdentifier === `cart` || componentIdentifier === `checkout`) &&
                  `${mapLocaleToMeaningfulFormat(router.locale).remove}`}
              </button>
            )}
          </section>
        </section>
        <section className="mt-6 grid grid-cols-3 gap-6 lg:mt-0 lg:gap-10">
          <section>
            <p className="text-sm font-semibold text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).unitPrice}</p>
            <div>
              {lineItem?.originalPrice ? (
                <p className="mt-2 text-base font-normal">
                  {CurrencyHelpers.formatForCurrency(lineItem?.originalPrice)}
                </p>
              ) : (
                <p className="mt-2 text-base font-normal">{CurrencyHelpers.formatForCurrency(lineItem?.price)}</p>
              )}
            </div>
          </section>
          <section>
            <p className="text-sm font-semibold text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).quantity}</p>
            {showQuantityInput ? (
              <label htmlFor={`cart-item-${index}`}>
                <section className="mt-2 flex w-[80px] items-center justify-between rounded-lg border">
                  <section>
                    <input
                      id={`cart-item-${index}`}
                      value={quantity}
                      type="number"
                      disabled={!lineItem?.isAvailable || lineItemQuantityDisabled}
                      onBlur={handleUpdateQuantity}
                      onChange={handleQuantity}
                      className={`${
                        !lineItem?.isAvailable || lineItemQuantityDisabled
                          ? 'cursor-not-allowed opacity-50'
                          : 'cursor-pointer'
                      } input-number w-[53px] rounded-l-lg border-none text-center focus:border-none focus:outline-none focus:ring-0`}
                      min="1"
                      max={maxQuantityPerVariant}
                    />
                  </section>
                  <section className="flex flex-col items-center justify-center">
                    {componentIdentifier === `thankyouPage` || componentIdentifier === `orderDetail` ? null : (
                      <>
                        {!lineItem?.isAvailable ? (
                          <>
                            <button
                              type="button"
                              aria-label="Product Quantity Increment"
                              id={lineItemTrim + 'ProductQtyIncrement'}
                              disabled={loading}
                            >
                              <MdOutlineKeyboardArrowUp className="cursor-not-allowed pt-1 text-2xl opacity-50" />
                            </button>
                            <button
                              type="button"
                              aria-label="Product Quantity Decrement"
                              id={lineItemTrim + 'productQtyDecrement'}
                            >
                              <MdOutlineKeyboardArrowDown className="cursor-not-allowed pb-1 text-2xl opacity-50" />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              aria-label="Product Quantity Increment"
                              id={lineItemTrim + 'productQtyIncrement'}
                              onClick={incrementCount}
                              disabled={loading}
                            >
                              <MdOutlineKeyboardArrowUp
                                className={`${
                                  lineItem?.count >= maxQuantityPerVariant
                                    ? 'cursor-not-allowed opacity-50'
                                    : 'cursor-pointer'
                                } pt-1 text-2xl`}
                              />
                            </button>
                            {lineItem?.count > 1 ? (
                              <button
                                type="button"
                                aria-label="Product Quantity Decrement"
                                id={lineItemTrim + 'productQtyDrecrement'}
                                onClick={decrementCount}
                                disabled={loading}
                              >
                                <MdOutlineKeyboardArrowDown className="pb-1 text-2xl" />
                              </button>
                            ) : (
                              <button
                                type="button"
                                aria-label="Product Quantity Decrement"
                                id={lineItemTrim + 'productQtyDrecrement'}
                              >
                                <MdOutlineKeyboardArrowDown className="cursor-not-allowed pb-1 text-2xl opacity-50" />
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </section>
                </section>
              </label>
            ) : (
              <p className="mt-2 pl-1">{lineItem?.count || 0}</p>
            )}
          </section>
          <section>
            <p className="text-sm font-semibold text-sol-300">{mapLocaleToMeaningfulFormat(router.locale).total}</p>
            {account && !account.isB2BApproved && lineItem?.originalPrice ? (
              <section>
                {
                  <strong className="mt-2 block text-left text-base font-normal leading-5 text-red-500 ">
                    {lineItem?.discountedPrice
                      ? totalPriceCalculation(lineItem?.discountedPrice, lineItem?.count)
                      : totalPriceCalculation(lineItem?.price, lineItem?.count)}
                  </strong>
                }
                <strong
                  className={`${
                    lineItem?.originalPrice && 'line-through'
                  } mt-2 block text-left text-base font-normal leading-5 text-sol-300`}
                >
                  {lineItem?.originalPrice
                    ? totalPriceCalculation(lineItem?.originalPrice, lineItem?.count)
                    : totalPriceCalculation(lineItem?.price, lineItem?.count)}
                </strong>
              </section>
            ) : account && account.isB2BApproved && lineItem?.originalPrice ? (
              <section>
                {
                  <strong className="mt-2 block text-left text-base font-normal leading-5 text-red-500 ">
                    {totalPriceCalculation(lineItem?.price, lineItem?.count)}
                  </strong>
                }
                <strong
                  className={`${
                    lineItem?.originalPrice && 'line-through'
                  } mt-2 block text-left text-base font-normal leading-5 text-sol-300`}
                >
                  {lineItem?.originalPrice
                    ? totalPriceCalculation(lineItem?.originalPrice, lineItem?.count)
                    : totalPriceCalculation(lineItem?.price, lineItem?.count)}
                </strong>
              </section>
            ) : (
              <section>
                {lineItem?.originalPrice ? (
                  <>
                    <p className="mt-2 text-base font-normal text-red-500">
                      {totalPriceCalculation(lineItem?.discountedPrice ?? lineItem?.price, lineItem?.count)}
                    </p>
                    <p className="mt-2 text-base font-normal line-through">
                      {totalPriceCalculation(lineItem?.originalPrice, lineItem?.count)}
                    </p>
                  </>
                ) : (
                  <section className="mt-2 text-base font-normal">
                    {lineItem?.discountedPrice ? (
                      <section>
                        <p className="mt-2 text-base font-normal text-red-500">
                          {totalPriceCalculation(lineItem?.discountedPrice, lineItem?.count)}
                        </p>
                        <p className="mt-2 text-base font-normal line-through">
                          {totalPriceCalculation(lineItem?.price, lineItem?.count)}
                        </p>
                      </section>
                    ) : (
                      <p className="mt-2 text-base font-normal">
                        {totalPriceCalculation(lineItem?.price, lineItem?.count)}
                      </p>
                    )}
                  </section>
                )}
              </section>
            )}
            {isDesktop && showAddToCartButton && (
              <>
                {!lineItem?.isAvailable || !validateWishlistItemQty(lineItem, cart, componentIdentifier) || loading ? (
                  <section>
                    <button
                      type="button"
                      id={lineItemTrim + 'desktopAddToCartBtn'}
                      className="btn-primary-small mt-5 w-full max-w-[113px] cursor-not-allowed p-2 text-white opacity-50 focus:outline-offset-2"
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                    </button>
                  </section>
                ) : (
                  <section>
                    <button
                      type="button"
                      id={lineItemTrim + 'desktopAddToCartBtn'}
                      className="btn-primary-small mt-5 w-full max-w-[113px] p-2 text-white focus:outline-offset-2"
                      onClick={addToCart}
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                    </button>
                  </section>
                )}
              </>
            )}
          </section>
        </section>
        {!isDesktop && showAddToCartButton && (
          <>
            {!lineItem?.isAvailable || !validateWishlistItemQty(lineItem, cart, componentIdentifier) ? (
              <section>
                <button
                  type="button"
                  id={lineItemTrim + 'mobileAddToCartBtn'}
                  className="btn-primary-small mt-5 w-full cursor-not-allowed px-4 py-2 text-white opacity-50"
                >
                  {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                </button>
              </section>
            ) : (
              <section>
                <button
                  type="button"
                  id={lineItemTrim + 'mobileAddToCartBtn'}
                  className="btn-primary-small mt-5 w-full px-4 py-2 text-white"
                  onClick={addToCart}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).addToCart}
                </button>
              </section>
            )}
          </>
        )}
        <RemoveProduct
          open={deleteModalOpen}
          onClose={closeDeleteModal}
          modalHeading={mapLocaleToMeaningfulFormat(router.locale).removeProduct}
          modalContent={mapLocaleToMeaningfulFormat(router.locale).sureWantToRemove}
          primaryBtnContent={mapLocaleToMeaningfulFormat(router.locale).remove}
          handleRemoveLineitem={handleRemoveLineItem}
          lineItemId={lineItem?.lineItemId}
          action="removeLineItem"
        />
      </section>
    </>
  );
}

export default LineItems;
