import { Account } from '@Types/account/Account';
import { StringHelpers } from 'helpers/stringHelpers';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
export const gaViewItem = (productName: string, variant: any, email: string) => {
  const dataLayer = window?.dataLayer;
  try {
    const viewItemObj = {
      currency: 'USD',
      value: variant?.price?.centAmount / 100,
      items: [
        {
          item_id: variant?.sku,
          item_name: productName,
          index: variant?.sku,
          item_variant: variant?.attributes?.categories[0],
          price: variant?.price?.centAmount / 100,
          quantity: 1,
        },
      ],
    };
    dataLayer.push({ ecommerce: null });
    if (email) {
      dataLayer.push({
        event: 'view_item',
        ecommerce: viewItemObj,
        email: StringHelpers?.convertToSHA256Hash(email),
      });
    } else {
      dataLayer.push({
        event: 'view_item',
        ecommerce: viewItemObj,
      });
    }
  } catch (error) {
    console.log('ga4 view item event fail');
  }
};
