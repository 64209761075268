import { Fragment, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Field, Form, Formik } from 'formik';
import { FaFacebook, FaPinterest, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import MarkdownView from 'react-showdown';
import BlogPostRichText from 'components/seo/blogPostRichText';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { desktop } from 'helpers/utils/screensizes';
import { content, mode, theme } from 'tailwind.config';
import ShareBlog from '../../products/details/share-product';
import BlogBreadcrumbs from '../BlogBreadcrumbs';

const BlogDetail = ({ blogCategories, blog }) => {
  const [isDesktop] = useMediaQuery(desktop);
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState('');
  const [shareBlog, setShareBlog] = useState(false);
  const [copyURLData, setCopyURLData] = useState(false);

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  // Share URLs
  const shareUrlTwitter = `https://twitter.com/intent/tweet?url=${currentUrl}`;
  const shareUrlFacebook = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
  const shareUrlPinterest = `https://pinterest.com/pin/create/button/?url=${currentUrl}`;

  const openShareBlogModal = () => {
    setShareBlog(true);
  };

  const closeShareBlogModal = () => {
    setShareBlog(false);
    setCopyURLData(false);
  };

  const copyURL = () => {
    setCopyURLData(true);
  };

  return (
    <>
      <BlogBreadcrumbs
        breadcrumLevelLink={true}
        activePages={[
          { title: 'Blogs', ctaLink: '/blogs' },
          { title: blog?.category?.title, ctaLink: blog?.category?.ctaLink },
          { title: blog?.title, ctaLink: '#' },
        ]}
      />
      <section className="mx-auto max-w-5xl px-4">
        <section className="px-5 lg:px-0">
          <h1 className="px-4 pb-6 pt-4 text-center text-3xl font-semibold">{blog?.title}</h1>
          <div
            className={`relative mx-auto mb-8 text-center before:absolute before:left-0 before:right-0 before:mx-auto before:block before:h-[2px] before:w-36 before:bg-sol-600 before:font-bold before:content-[''] lg:mb-6 lg:w-36`}
          ></div>
        </section>
        <section className="relative w-full">
          <div className="flex flex-col items-center justify-center md:flex-row">
            <div className="mb-4 flex items-center md:mb-0 md:mr-12">
              <p className="text-1xl leading-none text-gray-900">
                {mapLocaleToMeaningfulFormat(router.locale).tags} <span className="underline">{blog?.tag?.title}</span>
              </p>
            </div>
            <div className="mb-4 flex items-center md:mb-0 md:mr-12">
              <p className="text-1xl leading-none text-gray-900">By: {blog?.author}</p>
            </div>
            <div className="flex items-center">
              <p className="text-1xl leading-none text-gray-900">Date: {blog?.publishedAt}</p>
            </div>
          </div>
        </section>
        {blog?.landscapeDesktopImage?.url && blog?.landscapeMobileImage?.url && (
          <section className="mx-auto  flex">
            <ul className="h-auto w-full">
              <div className="my-8 flex items-center justify-center">
                <div className="relative max-w-screen-8xl">
                  <div className="relative">
                    <Image
                      loader={({ src }) => src}
                      src={isDesktop ? blog?.landscapeDesktopImage?.url : blog?.landscapeMobileImage?.url}
                      alt={blog?.altLandscape}
                      width={isDesktop ? 620 : 290}
                      height={isDesktop ? 300 : '100%'}
                      className={`w-full ${isDesktop ? 'h-[500px]' : 'h-auto'}`}
                    />
                  </div>
                </div>
              </div>
            </ul>
          </section>
        )}
        <section className="mx-auto mb-12">
          <ul className="mb-12 h-auto w-full">
            <MarkdownView markdown={blog?.longDescription} dangerouslySetInnerHTML />
          </ul>
          <ul className="flex flex-wrap items-center gap-5">
            <p>Share:</p>
            <li>
              <a href={shareUrlFacebook}>
                <FaFacebook size={24} />
              </a>
            </li>
            <li>
              <a href={shareUrlPinterest}>
                <FaPinterest size={24} />
              </a>
            </li>
            <li>
              <a href={shareUrlTwitter}>
                <FaTwitter size={24} />
              </a>
            </li>

            <li onClick={openShareBlogModal}>
              <a className="cursor-pointer">
                <MdEmail size={24} />
              </a>
            </li>
          </ul>
        </section>

        <section className="mx-auto mb-12">
          <div className={`grid grid-cols-1 gap-x-4 gap-y-10 sm:grid-cols-2 lg:grid-cols-3`}>
            {blogCategories?.map((content, index) => (
              <section key={index}>
                <Link href={content?.ctaLink}>
                  <a className="flex h-full rounded-t-md bg-gray-100">
                    <section className={`w-full rounded-t-[6px] bg-sol-100`}>
                      <section className="flex w-full justify-center">
                        <Image
                          loader={({ src }) => src}
                          src={content?.image?.url}
                          alt={content?.alt}
                          width={289}
                          height={223}
                          className="rounded-t-md"
                        />
                      </section>
                      <section className="bg-gray-100 p-4">
                        <h2
                          className={`min-h-[64px] pb-4 text-left text-2xl font-semibold leading-8 text-sol-300 line-clamp-2 ${
                            isDesktop ? 'lg:text-left' : ''
                          }`}
                        >
                          {content?.title}
                        </h2>
                        <p
                          className={`min-h-[24px] pb-4 text-left text-sm leading-6 text-sol-400 line-clamp-1 ${
                            isDesktop ? 'lg:text-left' : ''
                          }`}
                        >
                          {content?.blogTitle}
                        </p>
                        <Link href={content?.blogLink}>
                          <button className="rounded bg-gray-700 py-2 px-4 font-semibold text-white hover:bg-gray-700">
                            {content?.ctaLinkText ?? `${mapLocaleToMeaningfulFormat(router.locale).explore}`}
                          </button>
                        </Link>
                      </section>
                    </section>
                  </a>
                </Link>
              </section>
            ))}
          </div>
        </section>

        <ShareBlog
          modelOpen={shareBlog}
          onClose={closeShareBlogModal}
          shareProductHeading={mapLocaleToMeaningfulFormat(router.locale).shareByEmail}
          shareProductByLink={mapLocaleToMeaningfulFormat(router.locale).shareByLink}
          ShareProductByEmail={mapLocaleToMeaningfulFormat(router.locale).shareByEmail}
          url={currentUrl}
          copyURL={copyURL}
          btnContent={mapLocaleToMeaningfulFormat(router.locale).copy}
          linkCopy={copyURLData}
          shareButtonContent={mapLocaleToMeaningfulFormat(router.locale).share}
          cancelButtonContent={mapLocaleToMeaningfulFormat(router.locale).cancel}
        />
      </section>
      <BlogPostRichText data={blog} url={router.asPath} />
    </>
  );
};

export default BlogDetail;
