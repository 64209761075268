import { SEO_Variant } from './constants/localStorage';
import { DYNAMIC_CATEGORY_PATH } from './constants/route';
export class RouteHelpers {
  static formatCategoryPath = (path: string) => `${DYNAMIC_CATEGORY_PATH}${path}`;

  static getBaseURL = (path: string) => {
    return path?.split('?')[0];
  };

  static getFilterCount = (path: string, sortOptions: any, fbclidOptions = undefined) => {
    if (sortOptions === undefined) {
      if (fbclidOptions) {
        return path?.split('?')[1]?.replace(/[^=]/g, '').length - 1 || 0;
      } else {
        return path?.split('?')[1]?.replace(/[^=]/g, '').length || 0;
      }
    } else {
      if (fbclidOptions) {
        return path?.split('?')[1]?.replace(/[^=]/g, '').length - 2 || 0;
      } else {
        return path?.split('?')[1]?.replace(/[^=]/g, '').length - 1 || 0;
      }
    }
  };

  static getSEOVariant = (path: any, asPath: any) => {
    if (path) {
      let urlObject = new URL(`${path?.scheme + path?.hostName + asPath}`);
      urlObject.searchParams.delete('sort');
      let updatedUrl = urlObject.toString().replace(/\+/g, '%20');
      const str = updatedUrl?.split('=')[1];
      localStorage.setItem(SEO_Variant, decodeURIComponent(str));
      return decodeURIComponent(str);
    }
  };

  static removeSortQuery = (path: any, asPath: any) => {
    if (path) {
      let urlObject = new URL(`${path?.scheme + path?.hostName + asPath}`);
      urlObject.searchParams.delete('sort');
      let updatedUrl = urlObject.toString().replace(/\+/g, '%20');
      return updatedUrl;
    }
  };

  static removeFbQuery = (path: any, asPath: any) => {
    if (path) {
      let urlObject = new URL(`${path?.scheme + path?.hostName + asPath}`);
      urlObject.searchParams.delete('fbclid');
      let updatedUrl = urlObject.toString().replace(/\+/g, '%20');
      return updatedUrl;
    }
  };

  static updatePDPRouterQueryColor = (routerPath, colorToUpdate) => {
    const tempRouterPath = routerPath?.split('=');
    tempRouterPath[1] = colorToUpdate;
    let updatedTempRouterPath = tempRouterPath?.join('=');
    return updatedTempRouterPath;
  };
}
