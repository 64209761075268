import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';

const TwoColumnContentBlock = ({ content }) => {
  const dynamicWidth = calculateDynamicImageWidth(400, 500, 680);

  return (
    <>
      <section className="mx-auto mb-8 max-w-[1440px] px-5 md:px-10 lg:mb-20">
        <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:gap-5">
          {content?.map((c, index) => (
            <li key={index}>
              <Link href={c?.ctaLink}>
                <a className="flex h-full w-full">
                  <section className="h-full w-full rounded-t-[6px] bg-sol-100">
                    <section className="flex w-full justify-center">
                      <Image
                        loader={(options) => options.src}
                        width={670}
                        height={464}
                        src={format(AMPLIENCE_IMAGE_URL_FORMAT, [c?.image?.url, dynamicWidth])}
                        alt={c?.alt}
                        className="rounded-t-md"
                      />
                    </section>
                    <section className="flex justify-between p-4">
                      <h3 className="text-2xl font-semibold leading-[29px] text-sol-300">{c?.title}</h3>
                      <section className="ml-4 mt-1 shrink-0">
                        <button
                          type="button"
                          id={'twoColCtaLink' + c?.title?.trim()?.split(' ')?.join('')}
                          className="btn-primary-small inline-block px-4 py-2 text-sm !font-normal"
                        >
                          {c?.ctaLinkText}
                        </button>
                      </section>
                    </section>
                  </section>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default TwoColumnContentBlock;
